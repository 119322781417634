import React, { useState, useEffect } from 'react';
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

const envConstants = env_constants;

const OrderHistory = () => {
    const [data, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                // Retrieve authentication token from session storage or wherever it's stored
                const authToken = sessionStorage.getItem('token'); // Adjust based on your actual storage method
                const userSession = sessionStorage.getItem('user'); // Retrieve user data from session storage

                // Check if userSession is available
                if (!userSession) {
                    console.log("User session not found in session storage.");
                    setLoading(false);
                    return;
                }

                // Parse user session data to get user ID
                const user = JSON.parse(userSession);
                const userId = user.id;

                // Define headers with authorization token
                const headers = {
                    Authorization: `Bearer ${authToken}`
                };

                // Define the parameters to send
                const params = {
                    column: 'user_id',
                    value: userId
                };

                // Make Axios request with headers and params
                const response = await axios.get(`${env_constants.API_BASE_URL}/user-order`, {
                    headers,
                    params
                });

                if (response.data && Array.isArray(response.data.data)) {
                    setOrders(response.data.data);
                } else {
                    setOrders([]);
                    console.log("Unexpected response format.");
                }
            } catch (error) {
                setOrders([]);
                console.log("Failed to fetch order data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    return (
        <div className="container py-4">
            <Helmet>
                <title>Order History - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="My Order Listing" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="Order History, My Orders" />
            </Helmet>
            <h2 className="mb-4">Order History</h2>
            {loading ? (
                <div className="text-center">Loading...</div>
            ) : (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Total Price</th>
                            <th scope="col">Payment</th>
                            <th scope="col">Payment Status</th>
                            <th scope="col">Order Status</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((order, index) => (
                                <tr key={index}>
                                    <td>{new Date(order.created_at).toLocaleDateString()}</td>
                                    <td>${order.total_amount}</td>
                                    <td>{order.payment_method}</td>
                                    <td>{order.payment_status}</td>
                                    <td>{order.status}</td>
                                    <td>
                                        <Link className='btn btn-dark btn-sm' to={`/track-order?order_id=${order.id}`}>View Order</Link>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center">No orders found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default OrderHistory;
