
import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import env_constants from "../Constants";

function ProductImage({ galleryMedia, featuredImage }) {
  const [selectedImage, setSelectedImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (galleryMedia) {
      const images = galleryMedia
        .split(",")
        .map((img) => env_constants.API_BASE_PUBLIC_URL + img.trim());
      setSelectedImage(
        images.length > 0
          ? images[0]
          : featuredImage
            ? env_constants.API_BASE_PUBLIC_URL + featuredImage
            : ""
      );
    } else if (featuredImage) {
      setSelectedImage(env_constants.API_BASE_PUBLIC_URL + featuredImage);
    }
  }, [galleryMedia, featuredImage]);

  const handleImageClick = (newImageUrl) => {
    setSelectedImage(newImageUrl);
  };

  if (!galleryMedia && !featuredImage) {
    return <div>No images available</div>;
  }

  const images = galleryMedia
    ? galleryMedia
      .split(",")
      .map((img) => env_constants.API_BASE_PUBLIC_URL + img.trim())
    : [];

  const handleNextClick = () => {
    if (currentIndex + 3 < images.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="container image_size">
      <div className="single-product_image" style={{ backgroundImage: `url(${selectedImage})` }}></div>
     {/* <img src={selectedImage} className="product_image" alt="Main product" />*/}
      <div className="section_images" style={{ display: "flex", alignItems: "center" }}>
        {currentIndex > 0 && images.length > 3 && (
          <FaArrowLeft className="nav-icon" onClick={handlePrevClick} />
        )}
        {images.slice(currentIndex, currentIndex + 3).map((image, index) => (
          <img
            key={index}
            src={image}
            className={`product_section_image ${selectedImage === image ? "selected" : ""
              }`}
            alt={`Section ${index + 1}`}
            onClick={() => handleImageClick(image)}
            style={{ cursor: "pointer", margin: "0 5px" }}
          />
        ))}
        {currentIndex + 3 < images.length && (
          <FaArrowRight className="nav-icon" onClick={handleNextClick} />
        )}
      </div>
    </div>
  );
}

export default ProductImage; 