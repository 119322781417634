import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../Context/AuthContext';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Helmet} from "react-helmet-async";
import envConstants from "../Constants";

const SignUp = () => {
    const { user, register } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [submitBtnText, setSubmitBtnText] = useState('Sign Up');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
        _b_name: '',
        _b_email: '',
        _b_number: '',
        _b_street_address: '',
        _b_area_address: '',
        role_id: 5,
        created_by: 0,
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (user) {
            navigate('/'); // Redirect to profile or home if already logged in
        }
    }, [user, navigate]);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        if (event.target.checked) {
            setErrors((prevErrors) => {
                const { agree, ...rest } = prevErrors;
                return rest;
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            const phonePattern = /^[0-9]*$/;
            const namePattern = /^[a-zA-Z0-9 ]+$/; // Allow spaces in name
            const minLengthPattern = /^.{8,}$/;
            const uppercasePattern = /[A-Z]/;
            const specialCharPattern = /[!@#$%^&*]/;

            if (name === 'phone' && !phonePattern.test(value)) {
                newErrors.phone = 'Phone number must contain only numbers';
            } else if (name === 'name' && !namePattern.test(value)) {
                newErrors.name = 'Name must contain only letters, numbers, and spaces';
            } else if (name === 'password') {
                if (!minLengthPattern.test(value)) {
                    newErrors.password = 'Password must be at least 8 characters long';
                } else if (!uppercasePattern.test(value)) {
                    newErrors.password = 'Password must contain at least one uppercase letter';
                } else if (!specialCharPattern.test(value)) {
                    newErrors.password = 'Password must contain at least one special character';
                } else {
                    delete newErrors.password;
                }
            } else {
                delete newErrors[name];
            }

            return newErrors;
        });
    };

    const validate = () => {
        const newErrors = {};

        // Validation patterns
        const namePattern = /^[a-zA-Z0-9 ]+$/; // Allow spaces in name
        const phonePattern = /^[0-9]+$/;
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const minLengthPattern = /^.{8,}$/;
        const uppercasePattern = /[A-Z]/;
        const specialCharPattern = /[!@#$%^&*]/;

        if (!formData.name) newErrors.name = 'Name is required';
        else if (!namePattern.test(formData.name)) newErrors.name = 'Name must contain only letters, numbers, and spaces';

        if (!formData.email) newErrors.email = 'Email is required';
        else if (!emailPattern.test(formData.email)) newErrors.email = 'Invalid email format';

        if (!formData.password) newErrors.password = 'Password is required';
        else {
            if (!minLengthPattern.test(formData.password)) {
                newErrors.password = 'Password must be at least 8 characters long';
            } else if (!uppercasePattern.test(formData.password)) {
                newErrors.password = 'Password must contain at least one uppercase letter';
            } else if (!specialCharPattern.test(formData.password)) {
                newErrors.password = 'Password must contain at least one special character';
            }
        }

        if (!formData.phone) newErrors.phone = 'Phone is required';
        else if (!phonePattern.test(formData.phone)) newErrors.phone = 'Phone number must contain only numbers';

        if (!isChecked) newErrors.agree = 'You must agree to the terms and conditions';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setSubmitBtnText('Signing Up...');
        if (validate()) {
            try {
                const response = await register(formData);
                console.log('Registration Successful');
                navigate('/signin');
                toast.success('Registration successful!');
            } catch (error) {
                console.error('Registration failed:', error);
                toast.error(`Registration Failed: ${error.response?.data?.message || error.message}`);
                setSubmitBtnText('Sign Up');
            }
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="container p-0 p-sm-2 my-5">
            <Helmet>
                <title>Sign Up - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Create a new account" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="new account yazi, sign up yazi" />
            </Helmet>
                
            <div className="p-2">
                <div className="h4">Create An Account</div>
                <hr />
                <form onSubmit={handleSubmit} className="p-2 p-md-4 border">
                    <div className="">
                        <div className="col-md-8 row m-auto">
                            <div className="col-12 form-group">
                                <div className="h5">Personal Details</div>
                                <hr />
                            </div>
                            <div className="col-12 form-group mb-3">
                                <input type="text" className="form-control" placeholder="Name" name="name" id="name" value={formData.name} onChange={handleChange} />
                                {errors.name && <div className="text-danger">{errors.name}</div>}
                            </div>
                            <div className="col-6 form-group mb-3">
                                <input type="email" className="form-control" placeholder="Email" name="email" id="email" value={formData.email} onChange={handleChange} autoComplete="off"/>
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>
                            <div className="col-6 form-group mb-3 position-relative">
                                <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Password" name="password" id="password" value={formData.password} onChange={handleChange} autoComplete="off"/>
                                <label className="position-absolute" style={{ top: '8px', right: '30px', cursor: 'pointer' }} onClick={toggleShowPassword}>
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </label>
                                {errors.password && <div className="text-danger">{errors.password}</div>}
                            </div>
                            <div className="col-12 form-group mb-3">
                                <input type="number" className="form-control" placeholder="Phone" name="phone" id="phone" value={formData.phone} onChange={handleChange} />
                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                            </div>
                            <div className="col-12 form-group mt-2">
                                <div className="h5">Business Details (Optional)</div>
                                <hr />
                            </div>
                            <div className="form-group mb-3">
                                <input type="text" className="form-control" placeholder="Business Name" name="_b_name" id="businessName" value={formData.businessName} onChange={handleChange} />
                            </div>
                            <div className="col-6 form-group mb-3">
                                <input type="email" className="form-control" placeholder="Email" name="_b_email" id="businessEmail" value={formData.businessEmail} onChange={handleChange} autoComplete="off"/>
                            </div>
                            <div className="col-6 form-group mb-3">
                                <input type="text" className="form-control" placeholder="Phone" name="_b_number" id="businessPhone" value={formData.businessPhone} onChange={handleChange} />
                            </div>
                            <div className="col-12 form-group mb-3">
                                <input type="text" className="form-control" placeholder="Business Street Address" name="_b_street_address" id="businessStreetAddress" value={formData.businessStreetAddress} onChange={handleChange} />
                            </div>
                            <div className="form-group mb-3">
                                <input type="text" className="form-control" name="_b_area_address" id="businessAreaAddress" placeholder="Area Address" value={formData.businessAreaAddress} onChange={handleChange} />
                            </div>
                            <div className="form-group mb-3 text-center">
                                <input
                                    type="checkbox"
                                    className={`form-check-input ${isChecked ? 'bg-danger' : ''}`}
                                    name="_b_agree"
                                    id="_b_agree"
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label ms-2" htmlFor="_b_agree">I agree to the <Link to="/#" className="text-decoration-none text-danger text-dark-hover"> Terms of Service and Privacy Policy </Link></label>
                                {errors.agree && <div className="text-danger">{errors.agree}</div>}
                            </div>
                            <div className="col-12 form-group mb-3 text-center">
                                <button type="submit" className="shop-btn">{submitBtnText}</button>
                            </div>
                            <p className="text-center">Already have an account? <Link to="/signin" className="text-decoration-none text-danger text-dark-hover">Sign In</Link></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignUp;
