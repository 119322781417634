import React from "react";
import TestimonialCard from "../TestimonialCard";

const TestimonialSlider = ({testimonials}) => {
    return (
        <div className="row w-100 row-cols-1 row-cols-md-3 m-auto mt-5">
            {testimonials.map(testimonial => (
                <TestimonialCard testimonial={testimonial } />
            ))}
        </div>
    );
};

export default TestimonialSlider;