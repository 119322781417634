import { Helmet } from "react-helmet-async";
import  envConstants  from "../Constants";

const ThankYou = () => {
    const thankyou = '/assets/images/thank-you.jpg';
    return (
        <div className="text-center">
            <Helmet>
                <title>Thank You - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="order placed successfully" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="thank you" />
            </Helmet>
            <img src={thankyou} style={{width: "40%", margin: "20px 0px"}}/>
        </div>
    )
}

export default ThankYou;