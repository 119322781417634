import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { AuthContext } from '../Context/AuthContext';
import { Helmet } from "react-helmet-async";

const envConstants = env_constants;

const UpdateProfile = () => {
    const [profile, setProfile] = useState({
        personal: {},
        business: {}
    });
    const [loading, setLoading] = useState(true);
    const { token, updateUserProfile } = useContext(AuthContext);
    const navigate = useNavigate();
    const [submitBtnText, setSubmitBtnText] = useState('Update Profile');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${env_constants.API_BASE_URL}/profile`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const data = response.data.data;

                // Transform user_meta into key-value pairs
                const businessDetails = data.user_meta.reduce((acc, meta) => {
                    acc[meta.option] = meta.value;
                    return acc;
                }, {});

                // Combine the transformed business details with the rest of the profile data
                setProfile({
                    personal: data,
                    business: businessDetails
                });

                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile data:', error);
                setLoading(false);
            }
        };

        if (token) {
            fetchProfile();
        } else {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        if (!loading && !token) {
            navigate('/signin');
        }
    }, [loading, token, navigate]);

    const handleInputChange = (e, category) => {
        const { name, value } = e.target;
        setProfile((prevProfile) => ({
            ...prevProfile,
            [category]: {
                ...prevProfile[category],
                [name]: value
            }
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setSubmitBtnText('Updating Profile...');
        
        const updatedProfile = {
            name: profile.personal.name,
            email: profile.personal.email,
            password: profile.personal.password,
            phone: profile.personal.phone,
            role_id: profile.personal.role_id,
            created_by: profile.personal.created_by,
            _b_name: profile.business._b_name,
            _b_email: profile.business._b_email,
            _b_number: profile.business._b_number,
            _b_street_address: profile.business._b_street_address,
            _b_area_address: profile.business._b_area_address
        };

        try {
            const response = await axios.post(
                `${env_constants.API_BASE_URL}/profile/update`,
                updatedProfile,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            updateUserProfile(response.data.data); // Update user profile in AuthContext
            navigate('/my-account');
            toast.success('Profile Update Successful!');
        } catch (error) {
            toast.error('Error updating profile:', error);
            setSubmitBtnText('Update Profile');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!token) {
        return null; // or you can return a message or another component indicating the redirection
    }

    return (
        <div className="container py-4">
            <Helmet>
                <title>Update Profile - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Update your profile" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="update profile, update" />
            </Helmet>
            <h2>Update Profile</h2>
            <h4>Personal Detail</h4>
            <form onSubmit={handleUpdate} autoComplete='off'>
                <div className="row mt-4">
                    <div className="col-md-12 mb-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            name="name" 
                            value={profile.personal.name || ''} 
                            onChange={(e) => handleInputChange(e, 'personal')} 
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input 
                            type="email" 
                            disabled 
                            className="form-control" 
                            name="email" 
                            value={profile.personal.email || ''} 
                            onChange={(e) => handleInputChange(e, 'personal')} 
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input 
                            type="number" 
                            className="form-control" 
                            name="phone" 
                            value={profile.personal.phone || ''} 
                            onChange={(e) => handleInputChange(e, 'personal')} 
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input 
                            type="password" 
                            className="form-control" 
                            name="old_password" 
                            placeholder="Old Password" 
                            onChange={(e) => handleInputChange(e, 'personal')} 
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input 
                            type="password" 
                            className="form-control" 
                            name="password" 
                            placeholder="New Password" 
                            onChange={(e) => handleInputChange(e, 'personal')} 
                        />
                    </div>
                    <h4>Business Detail</h4>
                    <div className="col-md-12 mb-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            name="_b_name" 
                            value={profile.business._b_name || ''} 
                            onChange={(e) => handleInputChange(e, 'business')} 
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input 
                            type="email" 
                            className="form-control" 
                            name="_b_email" 
                            value={profile.business._b_email || ''} 
                            onChange={(e) => handleInputChange(e, 'business')} 
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input 
                            type="number" 
                            className="form-control" 
                            name="_b_number" 
                            value={profile.business._b_number || ''} 
                            onChange={(e) => handleInputChange(e, 'business')} 
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            name="_b_street_address" 
                            value={profile.business._b_street_address || ''} 
                            onChange={(e) => handleInputChange(e, 'business')} 
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            name="_b_area_address" 
                            value={profile.business._b_area_address || ''} 
                            onChange={(e) => handleInputChange(e, 'business')} 
                        />
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="profile-update-btn">{submitBtnText}</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UpdateProfile;
