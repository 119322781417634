import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import ShopList from "../Components/Sub-Components/ShopList";
import ShopSidebar from "../Components/ShopSidebar";
import envConstants from "../Constants";
import axios from "../Axiosapi";
import { Helmet } from "react-helmet-async";

const Shop = () => {
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [nextPage, setNextPage] = useState();
    const [previousPage, setPreviousPage] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState();
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loadingBrands, setLoadingBrands] = useState(true);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [errorCategories, setErrorCategories] = useState(null);
    const [errorProducts, setErrorProducts] = useState(null);
    const [errorBrands, setErrorBrands] = useState(null);

    const location = useLocation();

    const fetchCategories = useCallback(async () => {
        try {
            const response = await axios.get(`${envConstants.API_BASE_URL}/categories`);
            setCategories(response.data.data);
            setErrorCategories(null); // Reset error state
        } catch (error) {
            setErrorCategories(error);
            console.error(error);
        } finally {
            setLoadingCategories(false);
        }
    }, []);

    const fetchBrands = useCallback(async () => {
        try {
            const response = await axios.get(`${envConstants.API_BASE_URL}/brands`);
            setBrands(response.data.data);
            setErrorBrands(null); // Reset error state
        } catch (error) {
            setErrorBrands(error);
            console.error(error);
        } finally {
            setLoadingBrands(false);
        }
    }, []);

    const fetchProducts = useCallback(async (category = null, child_category = null, page = 1, brand = null, pagination = 20, json = false) => {
        setLoadingProducts(true);
        try {
            let url = `${envConstants.API_BASE_URL}/shop?page=${page}`;
            if (category) {
                url += `&category=${category}`;
            }
            if (child_category) {
                url += `&child_category=${child_category}`;
            }
            if (brand) {
                url += `&brand=${brand}`;
            }

            const response = await axios.get(url, {
                params: {
                    pagination: pagination,
                },
                responseType: json ? 'json' : 'json',
            });
            setProducts(response.data.data);
            setTotalCount(response.data.total);
            setTotalPages(response.data.last_page);
            setNextPage(page < response.data.last_page ? parseInt(page) + 1 : 1);
            setPreviousPage(page > 1 ? page - 1 : 1);
            setCurrentPage(page);
            setErrorProducts(null); // Reset error state
        } catch (error) {
            setErrorProducts(error);
            console.error(error);
        } finally {
            setLoadingProducts(false);
        }
    }, []);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const category = query.get('category');
        const child_category = query.get('child_category');
        const page = query.get('page');
        const brand = query.get('brand');

        fetchCategories();
        fetchBrands();
        fetchProducts(category, child_category, page, brand);
    }, [location.search]);

    return (
        <div className="container p-0 p-sm-2 my-5">
            <Helmet>
                <title>Shop - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="yazi shop" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="yazi tool shop, yazi store, yazi australia" />
            </Helmet>
            <div className="shop-section justify-content-between">
                <div className="shop-sidebar">
                    {loadingCategories && loadingBrands ? (
                        <div className="d-flex justify-content-center align-items-center p-5">
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : errorCategories ? (
                        <div className="d-flex justify-content-center">
                            Error loading categories and brands: {errorCategories?.message || "Unknown error"} <br />
                            {errorBrands?.message || "Unknown error"}
                        </div>
                    ) : (
                        <ShopSidebar categories={categories} brands={brands} />
                    )}
                </div>
                <div className="shop-list">
                    {loadingProducts ? (
                        <div className="d-flex justify-content-center align-items-center p-5">
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : errorProducts ? (
                        <div className="d-flex justify-content-center">Error loading products: {errorProducts.message}</div>
                    ) : (
                        <ShopList
                            products={products}
                            categories={categories}
                            brands={brands}
                            badge=""
                            nextPage={nextPage}
                            previousPage={previousPage}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            totalCount={totalCount}
                            brand={new URLSearchParams(location.search).get('brand')}
                            child_category={new URLSearchParams(location.search).get('child_category')}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Shop;