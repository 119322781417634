import React, { useState } from "react";
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const envConstants = env_constants;

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [password_confirmation, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const [submitBtnText, setSubmitBtnText] = useState('Update Password');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = async (event) => {
        setSubmitBtnText('Updating Password...');
        event.preventDefault();

        if (password !== password_confirmation) {
            toast.error("Passwords do not match!");
            return;
        }

        const email = sessionStorage.getItem('email');
        const otp = sessionStorage.getItem('otp');

        if (!email || !otp) {
            toast.error("Email or OTP is invalid or expired");
            return;
        }

        try {
            const response = await axios.post(`${env_constants.API_BASE_URL}/update-password`, { 
                email,
                otp,
                password, 
                password_confirmation 
            });
            toast.success("Password updated successfully!");
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('otp');
            navigate('/signin');
        } catch (error) {
            toast.error("An error occurred. Please try again.");
            setSubmitBtnText('Update Password');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="container p-0 p-sm-2 my-5">
            <Helmet>
                <title>Reset Password - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Reset account password" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="new account yazi, sign up yazi, reset password" />
            </Helmet>
            <div className="p-2">
                <div className="h4">Reset Your Password</div>
                <hr />
                <form onSubmit={handleSubmit} className="p-2 p-md-4 border">
                    <div className="">
                        <div className="col-md-8 row m-auto">
                            <div className="col-12 form-group mb-3 position-relative">
                                <label className="form-label" htmlFor="password">Password</label>
                                <input 
                                    type={showPassword ? "text" : "password"} 
                                    className="form-control" 
                                    id="password" 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    required 
                                />
                                <label className="position-absolute" style={{ top: '40px', right: '30px', cursor: 'pointer' }} onClick={toggleShowPassword}>
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </label>
                            </div>
                            <div className="col-12 form-group mb-3 position-relative">
                                <label className="form-label" htmlFor="confirm-password">Confirm Password</label>
                                <input 
                                    type={showConfirmPassword ? "text" : "password"} 
                                    className="form-control" 
                                    id="confirm-password" 
                                    value={password_confirmation} 
                                    onChange={(e) => setConfirmPassword(e.target.value)} 
                                    required 
                                />
                                <label className="position-absolute" style={{ top: '40px', right: '30px', cursor: 'pointer' }} onClick={toggleShowConfirmPassword}>
                                    <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                </label>
                            </div>
                            <div className="col-12 form-group mb-3 text-center">
                                <button type="submit" className="shop-btn">{submitBtnText}</button>
                            </div>
                            <div className="col-12 form-group mb-3 text-center">
                                <p>Return to <Link to="/signin" className="text-decoration-none text-danger text-dark-hover">Sign In</Link></p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
