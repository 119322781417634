import React, { useState } from 'react';
import envConstants from "../../Constants";
import { Link } from 'react-router-dom';

const BrandSlider = ({brands}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalSlides = brands.length;
  let visibleSlides = 7;
  let width = window.innerWidth;

  if (width < 1200 && width >= 1024) {
    visibleSlides = 6;
  } else if (width < 1024 && width >= 768) {
    visibleSlides = 4;
  } else if (width < 768 && width >= 480) {
    visibleSlides = 3;
  } else if (width < 480) {
    visibleSlides = 2;
  } else {
    visibleSlides = 7;
  }

  const handleLeftClick = () => {
    const newIndex = currentIndex === 0 ? totalSlides - visibleSlides : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const handleRightClick = () => {
    const newIndex = currentIndex === totalSlides - visibleSlides ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const sliderStyle = {
    transform: `translateX(-${currentIndex * (100 / visibleSlides)}%)`
  };


  return (
    <div className="b-slider-container {totalSlides ? '' : 'd-none'}">
      <div className="b-slider" style={sliderStyle}>
        {brands.map((brand, index) => (
          <div className="b-slide text-center" key={index}>
            <Link to={`/shop?brand=`+brand.permalink} className="text-decoration-none text-dark">
            <img src={brand.logo_image ? envConstants.API_BASE_PUBLIC_URL+brand.logo_image : 'https://via.placeholder.com/150'} alt={`Brand ${index + 1}`} />
            </Link>
          </div>
        ))}
      </div>
      <button className="b-arrow b-left" onClick={handleLeftClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
      </button>
      <button className="b-arrow b-right" onClick={handleRightClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" strokeLinecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
      </button>
    </div>
  );
};

export default BrandSlider;
