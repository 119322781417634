import { Helmet } from "react-helmet-async";
import envConstants from "../Constants";
const ReturnPolicy = () => {
    return (
        <div className="container my-4">
            <Helmet>
                <title>Return Policy - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Our core philosophy is simple: to be the leading industrial tool company in Australia. We achieve this by deeply valuing our customers, suppliers, and staff. We offer an extensive range of industrial tools at competitive prices, reinvesting our profits into the business to maintain low overheads and attract top talent." />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="yazi return policy, return policy yazi" />
            </Helmet>
            <h2>Return Policy</h2>
            <p>To return an item to Yazi Auto, please follow the instructions provided by the company. The specific steps may vary, so it’s best to refer to our official website or contact our customer service for accurate information. Here are some general guidelines that might be helpful:</p>
            <h4>1. Fill out the Returns Form</h4>
            <p>Visit the Yazi Auto website and find our Returns Form. Fill out the form with the necessary details about the product you wish to return. Provide accurate information to ensure a smooth return process.</p>
            <h4>2. Contact Customer Service</h4>
            <p>After submitting the Returns Form, our customer service team will reach out to you. They will provide further instructions and may offer a pre-paid label for returning the item. Please listen to their guidance and follow their recommendations.</p>
            <h4>3. Package the Item Securely</h4>
            <p>Before sending back the item, ensure it is in its original condition with all components and packaging intact. If there are any accessories or manuals that came with the item, include them in the return package. Securely wrap and package the item to prevent any damage during transit.</p>
            <h4>4. Arrange for Return Shipping</h4>
            <p>Depending on the instructions provided by Yazi Auto, you may need to schedule a collection for a day that suits you or drop off the package at a designated location. Follow our guidance on how to arrange for return shipping.</p>
            <h4>5. Return the Item</h4>
            <p>On the scheduled collection date or when dropping off the package, hand it over to the delivery driver or bring it to the designated location as instructed by Yazi Auto. Make sure to keep any receipts or tracking numbers provided by the shipping carrier for future reference.</p>
            <h4>6. Refund Process</h4>
            <p>
                Once Yazi Auto receives the returned item, we will aim to issue refunds within a specific period, which may vary. The refund will typically be processed using the same payment method used for the original purchase. If you have any concerns or questions about the refund, please reach out to our customer service team for assistance.
                Remember to familiarize yourself with Yazi Auto’s full Returns Policy and any additional terms and conditions we may have regarding returns.
            </p>
            <h4>Return Request Form</h4>
            <p><a href="/return-order" className="text-decoration-none text-dark fw-bold">Click here to access the Return Request Form</a></p>
        </div>
    )
}

export default ReturnPolicy;