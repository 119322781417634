import React from "react";

const TestimonialCard = ({ testimonial }) => {
    return (
        <div className="col p-4 bg-light border-end">
            <div className="ratings">⭐⭐⭐⭐⭐</div>
            <p className="">{testimonial.message}</p>
            <div className="d-flex justify-content-start align-items-center">
                <img src="https://i.ibb.co/0Jg6k2d/Rectangle-6.png" height="40px" className="rounded-circle" alt="testimonials"/>
                <div className="ps-2">{testimonial.name}</div>
            </div>
        </div>
    );
};

export default TestimonialCard;