import React, { useState } from 'react';
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet-async";

const envConstants = env_constants;

const Contactus = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });
    const [submitBtnText, setSubmitBtnText] = useState('Submit');
    const [submitBtnDisable, setSubmitBtnDisable] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const apiUrl = `${env_constants.API_BASE_URL}/contact-us`;

        const payload = {
            f_name: formData.firstName,
            l_name: formData.lastName,
            email: formData.email,
            phone: formData.phoneNumber,
            subject: formData.subject,
            message: formData.message,
        };

        try {
            setSubmitBtnText('Submitting...');
            setSubmitBtnDisable(true);
            const response = await axios.post(apiUrl, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success('Form Submitted Successfully');
            setSubmitBtnText('Submit');
            setSubmitBtnDisable(false);
            // Clear form fields
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                subject: '',
                message: ''
            });
        } catch (error) {

            toast.error(`Error submitting form: ${error.message}`);

            setSubmitBtnText('Submit');
            setSubmitBtnDisable(false);

        }
    };

    return (
        <div className="container py-5 my-3">
            <Helmet>
                <title>Contact Us - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="send your queries using this form, contact us yazi" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="yazi contact us, yazi support" />
            </Helmet>
            <h2>Contact Us</h2>
            <form onSubmit={handleSubmit}>
                <div className="row mt-4">
                    <div className="col-md-6 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-12 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-12 mb-4">
                        <textarea
                            className="form-control"
                            placeholder="Message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-12">
                        <button className="contact-us-btn" type="submit" disabled={submitBtnDisable}>{submitBtnText}</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Contactus;
