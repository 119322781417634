import React, { useState } from "react";
import envConstants from "../Constants";

const Tabs = ({ product }) => {
    const [activeTab, setActiveTab] = useState("Description");
    const productimagenotfound = '/assets/images/product-image-not-found.png';

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const removeTags = (str) => {
        if (!str || typeof str !== 'string') return '';
        return str.replace(/<[^>]*>?/gm, '');
    };

    const baseUrl = envConstants.API_BASE_PUBLIC_URL;

    return (
        <div>
            <div className="Descriptiontabs">
                <div
                    className={`Descriptiontab ${activeTab === "Description" ? "active" : ""}`}
                    onClick={() => handleTabClick("Description")}
                >
                    Description
                </div>
                <div
                    className={`Descriptiontab ${activeTab === "Product Details" ? "active" : ""}`}
                    onClick={() => handleTabClick("Product Details")}
                >
                    Product Details
                </div>
                <div
                    className={`Descriptiontab d-none ${activeTab === "Shipping" ? "active" : ""}`}
                    onClick={() => handleTabClick("Shipping")}
                >
                    Shipping
                </div>
            </div>
    
            <div className="tab-content">
                {activeTab === "Description" && (
                    <div className="product-description description_container">
                        <p className="fw-medium fontsize mt-4 mb-5">
                            {product?.long_description ? removeTags(product.long_description) : "No Description"}
                        </p>
                    </div>
                )}
                {activeTab === "Product Details" && (
                    <div className="description_container pt-4">
                        <div>
                            {product.brand && (
                                <img
                                    src={`${baseUrl}${product.brand.cover_image}`}
                                    onError={(e) => e.target.src = productimagenotfound}
                                    className="brand_logos"
                                    alt="Brand logo"
                                />
                            )}
                            <div className="d-flex gap-3 fw-medium text-secondary mt-3 font_size">
                                <p className="mb-1">SKU</p>
                                <p className="mb-1"> : {product.sku}</p>
                            </div>
                            <div className="d-flex gap-3 fw-medium text-secondary font_size">
                                <p className="mb-1">In Stock</p>
                                <p className="mb-1"> : {parseFloat(product.product_meta.find(meta => meta.option === '_stock').value)}</p>
                            </div>
                        </div>
    
                        <div className="container height_setting mb-5">
                            <div className="row position-relative">
                                <div className="col-12 col-md-4 font_weight font_size border-ra pading_start ps-0 weight-title">
                                    <p className="bg_color p-2 mb-2 ">Weight</p>
                                </div>
                                <div className="col-12 col-md-8 font_size position border-ra pading_start pe-0 weight-value">
                                    <p className="bg_color p-2 mb-2 responsive_details1">
                                        {parseFloat(product.product_meta.find(meta => meta.option === '_weight').value)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === "Shipping" && (
                    <div className="description_container">
                        <div className="shipping_container">
                            <p className="shipping_info mt-4">
                                Use collapsible tabs for more detailed information that will
                                help customers make a purchasing decision.
                            </p>
                            <p className="shipping_info mt-1">
                                Ex: Shipping and return policies, size guides, and other common
                                questions.
                            </p>
                            <ul className="shipping_info shipping_info_padding mt-1 mb-5">
                                <li>Paste the label on a flat surface on the package</li>
                                <li>
                                    Make sure that both 1D and 2D barcodes are clearly visible
                                </li>
                                <li>
                                    Ensure that the label is smooth and isn’t creased or wrinkled
                                </li>
                                <li>Check for any tears, dents, holes or scratches</li>
                                <li>
                                    Pack your product tightly, with the right size packaging
                                </li>
                                <li>
                                    Ensure both barcodes are on a flat surface of the package
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tabs;