import React, { useState, useEffect, useRef } from "react";
import ProductCard from "../ProductCard";

const ProductSlider = ({ products = [], categories = [], badge }) => {
    
    const [currentIndex, setCurrentIndex] = useState(0);
    const [cardWidth, setCardWidth] = useState(0);
    const [gapWidth, setGapWidth] = useState(14.5);
    const trackRef = useRef(null);

    const updateDimensions = () => {
        if (trackRef.current) {
            const card = trackRef.current.querySelector(".product-card");
            if (card) {
                setCardWidth(card.offsetWidth);
            }
        }

        if (window.innerWidth <= 480) {
            setGapWidth(19); // Gap for very small screens
        } else if (window.innerWidth <= 768) {
            setGapWidth(18.8); // Gap for small screens
        } else if (window.innerWidth <= 1024) {
            setGapWidth(18.8); // Gap for tablet screens
        } else if (window.innerWidth <= 1366) {
            setGapWidth(18.8); // Gap for medium screens
        } else {
            setGapWidth(14.5); // Default gap for larger screens
        }
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);

    useEffect(() => {
        // Recalculate dimensions after products are loaded
        updateDimensions();
    }, [products]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => prevIndex - 1);
    };

    const handleTransitionEnd = () => {
        if (currentIndex >= products.length) {
            setCurrentIndex(0);
            trackRef.current.style.transition = "none";
            trackRef.current.style.transform = `translateX(0px)`;
        } else if (currentIndex < 0) {
            setCurrentIndex(products.length - 1);
            trackRef.current.style.transition = "none";
            trackRef.current.style.transform = `translateX(-${(products.length - 1) * (cardWidth + gapWidth)}px)`;
        }
    };

    useEffect(() => {
        if (trackRef.current) {
            trackRef.current.style.transition = "transform 0.5s ease-in-out";
            trackRef.current.style.transform = `translateX(-${currentIndex * (cardWidth + gapWidth)}px)`;
        }
    }, [currentIndex, cardWidth, gapWidth]);

    return (
        <div className="position-relative">
            <button className="product-slider-btn slider-btn-left" onClick={prevSlide}></button>

            <div className="d-flex mt-4 slider">
                <div
                    className="slider-track"
                    ref={trackRef}
                    onTransitionEnd={handleTransitionEnd}
                    style={{ transform: `translateX(-${currentIndex * (cardWidth + gapWidth)}px)` }}>
                    {products.map((product, index) => (
                        <ProductCard key={index} product={product} categories={categories} badge={badge} />
                    ))}
                    {products.map((product, index) => (
                        <ProductCard key={`${index}-clone`} product={product} categories={categories} badge={badge} />
                    ))}
                </div>
            </div>

            <button className="product-slider-btn slider-btn-right" onClick={nextSlide}></button>
        </div>
    );
};

export default ProductSlider;