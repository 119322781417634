import React, { useState, useEffect } from "react";
import envConstants from "../Constants";
import BrandSlider from "../Components/Sub-Components/BrandSlider";
import ProductSlider from "../Components/Sub-Components/ProductSlider";
import CategoryCard from "../Components/CategoryCard";
import PostGrid from "../Components/Sub-Components/PostsGrid";
import TestimonialSlider from "../Components/Sub-Components/TestimonialSlider";
import HomeSlider from "../Components/Sub-Components/HomeSlider";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import axios from "../Axiosapi";
import { useNavigate } from 'react-router-dom';

const posts = [
    {
        id: 1,
        title: "Hyper Tough Lithium Cordless Brushless Impact Driver",
        thumbnail: "https://prestashop.coderplace.com/PRS02/PRS02044/demo/img/ets_blog/post/6d023f6531-03.jpg",
        created_at: "2022-01-01",
    },
    {
        id: 2,
        title: "Hyper Tough Lithium Cordless Brushless Impact Driver",
        thumbnail: "https://prestashop.coderplace.com/PRS02/PRS02044/demo/img/ets_blog/post/6e71b22d24-01.jpg",
        created_at: "2022-01-01",
    },
    {
        id: 3,
        title: "Hyper Tough Lithium Cordless Brushless Impact Driver",
        thumbnail: "https://prestashop.coderplace.com/PRS02/PRS02044/demo/img/ets_blog/post/ddc4894dd0-05.jpg",
        created_at: "2022-01-01",
    },
    {
        id: 4,
        title: "Hyper Tough Lithium Cordless Brushless Impact Driver",
        thumbnail: "https://prestashop.coderplace.com/PRS02/PRS02044/demo/img/ets_blog/post/d92d248b3a-02.jpg",
        created_at: "2022-01-01",
    }
];
const testimonials = [
    {
        id: 1,
        name: "John Doe",
        message: "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Donec euismod, nisl eget consectetur adipiscing elit.",
        rating: 4,
    },
    {
        id: 2,
        name: "Jane Smith",
        message: "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Donec euismod, nisl eget consectetur adipiscing elit.",
        rating: 5,
    },
    {
        id: 3,
        name: "Michael Johnson",
        message: "Lorem ipsum dolor sit amet, consectetur adipis cing elit. Donec euismod, nisl eget consectetur adipiscing elit.",
        rating: 3,
    },
];



const Home = () => {

    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [brandSlides, setBrandSlides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const offerBanner = '/assets/images/banner-image.png';
    const giftBanner = '/assets/images/cms-banner2.jpg';
    const home404 = '/assets/images/home-404.png';
    const navigate = useNavigate();

    useEffect(() => {


        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/brands`);
                setBrands(response.data.data); // Update to use response.data.data
            } catch (error) {
                setError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/categories`);
                setCategories(response.data.data); // Update to use response.data.data
            } catch (error) {
                setError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchLatestProducts = async ({ pagination = 20, json = false } = {}) => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/latest-products`, {
                    params: { pagination },
                    responseType: json ? 'json' : 'json',
                });
                setLatestProducts(response.data.data.data); // Correctly accessing the nested data array
            } catch (error) {
                setError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        const featuredProducts = async () => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/virtual-products`);
                setFeaturedProducts(response.data.data); // Update to use response.data.data
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        {/* const fetchPopularProducts = async () => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/best-seller-products?repetition_number=${envConstants.POPULAR_COUNT}`);
                setPopularProducts(response.data.data); // Update to use response.data.data
            } catch (error) {
                setError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        }; */}

        const fetchBrandsSlides = async () => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/brand-sliders`, {
                    timeout: 5000, // Set timeout to 5 seconds
                });
                setBrandSlides(response.data.data);
            } catch (error) {
                setError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchBrands();
        fetchCategories();
        fetchLatestProducts();
        featuredProducts();
        {/* fetchPopularProducts(); */ }
        fetchBrandsSlides();

    }, []);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center p-5">
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow spinner-grow-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    } else if (error && error.message && error.message.includes("429")) {
        navigate('/too-many-requests');
        return null; 
    } else if (error) {
        return (
            <div className="d-flex justify-content-center flex-column align-items-center">
                <img
                    src={home404}
                    style={{ width: "40%", margin: "20px 0px" }}
                    alt="Error Image"
                />
                <div style={{ marginTop: "10px", color: "red", textAlign: "center" }}>
                    {error.message || "An unexpected error occurred."}
                </div>
            </div>
        );
    }


    return (

        <div className="container p-0 p-sm-2">
            <Helmet>
                <title>Home - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content={envConstants.STORE_META_DESCRIPTION ? envConstants.STORE_META_DESCRIPTION : 'online store, purchase online'} />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content={envConstants.STORE_META_KEYWORDS ? envConstants.STORE_META_KEYWORDS : 'online store, purchase online'} />
            </Helmet>
            <div className="home-slider justify-content-between">
                <div className="main-home-slide">
                    <HomeSlider />
                </div>
                <div className="main-home-cards">
                    <div className="main-home-banner banner-1">
                        {/* <p>$29.99 ONLY</p> */}
                        <h2><span>Automotive Covers</span></h2>
                        <a href="/shop?page=1&brand=automotive-covers-">Shop Now</a>
                    </div>
                    <div className="main-home-banner banner-2">
                        {/* <p>Start From $9.99</p> */}
                        <h2><span>Towing Trailers</span> Tiedowns</h2>
                        <a href="/shop?page=1&brand=towing-trailers-tiedowns">Shop Now</a>
                    </div>
                </div>
            </div>
            <div className="website-services d-flex justify-content-evenly flex-wrap">


                <div className="services">
                    <div className="truck-icon"></div>
                    <h3>Easy Free Delivery</h3>
                    <p>On orders over $100</p>
                </div>

                <div className="services">
                    <div className="safty-icon"></div>
                    <h3>Premium Warranty</h3>
                    <p>Up to 2 years</p>
                </div>

                <div className="services border-end-0">
                    <div className="return-icon"></div>
                    <h3>Easy Free Return</h3>
                    <p>365 days return</p>
                </div>
            </div>


            <div className="category-section">
                <h2 className="heading-2">Shop by Categories</h2>
                <p className="theme-text">Check out all the feature categories for simple product discovery.</p>

                <CategoryCard categories={categories} />

            </div>
            <div className="porduct-section">
                <h2 className="heading-2">Featured Products</h2>
                <p className="theme-text">Check out our collection of the top Featured Products that encourage conversion.</p>
                <ProductSlider products={featuredProducts} categories={categories} badge="Featured" />
            </div>
          {/*  <div className="gift-banner d-flex justify-content-start align-items-center px-5" style={{ backgroundImage: `url(${giftBanner})` }}>
                <div className="gift-banner-box">TOOL</div>
                <div className="gift-banner-box-details">
                    <div className="text-light">$20 Gift Bonus</div>
                    <div className="text-warning">Special offer for today only</div>
                </div>
            </div>*/}
            <div className="porduct-section">
                <h2 className="heading-2">New Products</h2>
                <p className="theme-text">Check out our collection of the top New Products that encourage conversion.</p>
                <ProductSlider products={latestProducts} categories={categories} badge="New" />
            </div>
            <div className="card-banner">
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="card-banner-box card-banner-box-1">
                        {/* <h5>START FROM $129</h5> */}
                        <h3><span>Batteries</span></h3>
                        <Link to="/shop?page=1&brand=battery" className="shop-btn">Shop Now</Link>
                    </div>
                    <div className="card-banner-box card-banner-box-2">
                        {/*  <h5>BIG SAVING ON</h5> */}
                        <h3><span>Headlights</span> and Torches</h3>
                        <Link to="/shop?page=1&brand=headlights-and-torches" className="shop-btn">Shop Now</Link>
                    </div>
                </div>
            </div>
            {/*<div className="porduct-section">
                <h2 className="heading-2">Bestseller Products</h2>
                <p className="theme-text">Check out our collection of the top Bestseller Products that encourage conversion.</p>
                <ProductSlider products={popularProducts} categories={categories} badge="Popular"/>
            </div> */}
            <div className="porduct-section d-none">
                <h2 className="heading-2">See What Our Customers Says</h2>
                <p className="theme-text">Check our latest reviews from customers and say your feedback.</p>
                <TestimonialSlider testimonials={testimonials} />
            </div>
            <div className="offer-banner p-md-3 p-3 mb-5 d-flex justify-content-end align-items-center" style={{ backgroundImage: `url(${offerBanner})` }}>
                <div className="offer-banner-box">
                    <h5 className="text-warning">&nbsp;Limited Time Offer</h5>
                    <h3 className="pb-md-3 pb-2"><span>CAR ACCESSORIES</span><br />Wheel & Locks</h3>
                    <a href="/shop?child_category=Wheel--Lock-Nuts&page=1" className="shop-btn btn btn-light">Shop Now</a>
                </div>
            </div>
            <BrandSlider brands={brands} />
            <br />
            {/* <PostGrid posts={posts} /> */}
        </div>
    );
};

export default Home;
