import React, { useState, useRef } from 'react';
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet-async";

const envConstants = env_constants;

const ReturnOrder = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        order_id: '',
        subject: '',
        message: '',
        file_path: null // Added file_path field
    });
    const [submitBtnText, setSubmitBtnText] = useState('Submit');
    const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
    const fileInputRef = useRef(null); // Reference to the file input

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'file_path') {
            const file = files[0];
            const maxSize = 5 * 1024 * 1024; // 5MB in bytes

            if (file.size > maxSize) {
                toast.error('File size exceeds 5MB');
                return;
            }

            setFormData({
                ...formData,
                [name]: file // Set the file object
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const apiUrl = `${env_constants.API_BASE_URL}/order-return`;
    
        const payload = new FormData();
        payload.append('f_name', formData.firstName);
        payload.append('l_name', formData.lastName);
        payload.append('email', formData.email);
        payload.append('phone', formData.phoneNumber);
        payload.append('order_id', formData.order_id);
        payload.append('subject', formData.subject);
        payload.append('message', formData.message);
        if (formData.file_path) {
            payload.append('file_path', formData.file_path);
        }
    
        try {
            setSubmitBtnText('Submitting...');
            setSubmitBtnDisable(true);
            const response = await axios.post(apiUrl, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(`${response.data.message}`);
            setSubmitBtnText('Submit');
            setSubmitBtnDisable(false);
            // Clear form fields
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                order_id: '',
                subject: '',
                message: '',
                file_path: null // Reset file field
            });
            fileInputRef.current.value = null; // Clear the file input
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                toast.error(`Error submitting form: ${error.response.data.message}`);
            } else if (error.request) {
                // The request was made but no response was received
                toast.error('Error submitting form: No response from server.');
            } else {
                // Something happened in setting up the request that triggered an Error
                toast.error(`Error submitting form: ${error.message}`);
            }
            setSubmitBtnText('Submit');
            setSubmitBtnDisable(false);
        }
    };
    
    return (
        <div className="container py-5 my-3">
            <Helmet>
                <title>Return Order - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="send your queries using this form, contact us yazi" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="yazi contact us, yazi support" />
            </Helmet>
            <h2>Return</h2>
            <form onSubmit={handleSubmit}>
                <div className="row mt-4">
                    <div className="col-md-6 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Order ID"
                            name="order_id" 
                            value={formData.order_id}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            type="file"
                            className="form-control"
                            name="file_path"
                            accept=".png, .jpg, .jpeg, .pdf, .txt"
                            onChange={handleChange}
                            ref={fileInputRef} // Reference to the file input
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4">
                        <input
                            className="form-control"
                            placeholder="Message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </div>
                    
                    <div className="col-md-12">
                        <button className="contact-us-btn" type="submit" disabled={submitBtnDisable}>{submitBtnText}</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ReturnOrder;
