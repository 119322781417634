import axios from 'axios';

// Your Axios configuration and interceptor setup
const Axios = axios.create({
    // your axios config here
});

Axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 429) {
            const redirectedAt = sessionStorage.getItem('tooManyRequestsRedirectedAt');
            const now = new Date().getTime();

           


            if (!redirectedAt || now - redirectedAt > 45000) {
                sessionStorage.setItem('tooManyRequestsRedirectedAt', now.toString());
                window.location.href = '/too-many-requests';
            }
        }
        return Promise.reject(error);
    }
);

export default Axios;



{/*import axios from 'axios';

// Your Axios configuration and interceptor setup
const Axios = axios.create({
    // your axios config here
});

Axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 429) {
            const redirectedAt = sessionStorage.getItem('tooManyRequestsRedirectedAt');
            const now = new Date().getTime();

            if (!redirectedAt || now - redirectedAt > 45000) {
                sessionStorage.setItem('tooManyRequestsRedirectedAt', now.toString());
                window.location.href = '/too-many-requests';
            }
        }
        return Promise.reject(error);
    }
);

export default Axios; */}



