import { Helmet } from "react-helmet-async";
import envConstants  from "../Constants";
const NotFound = () => {
    const notfound = '/assets/images/404.png';
    return (
        <div className="text-center">
            <Helmet>
                <title> Page Not Found - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="page not found" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="page not found at yazi" />
            </Helmet>
            <img src={notfound} style={{width: "40%", margin: "20px 0px"}}/>
        </div>
    )
}

export default NotFound