import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { AuthContext } from '../Context/AuthContext';
import { toast } from 'react-toastify';

const Address = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const [submitBtnText, setSubmitBtnText] = useState('Add Address');

    
    const [formData, setFormData] = useState({
        f_name: '',
        l_name: '',
        email: '',
        phone: '',
        city: '',
        state: '',
        suburb: '',
        post_code: '',
        street_address: '',
        address_type: '',
        company: '',
        user_id: user ? user.id : 0,
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        setSubmitBtnText('Adding Address...');
        e.preventDefault();
        try {
            const response = await axios.post(`${env_constants.API_BASE_URL}/user-addresses/store`, formData);
            if (response.status === 200) {
                navigate('/my-account'); 
                toast.success('Address Add Successful!');
            }
        } catch (error) {
            toast.error('Error adding address:', error);
            setSubmitBtnText('Add Address');
        }
    };

    return (
        <div className="container my-5">
            <h1>Add New Address</h1>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <input type="text" name="f_name" value={formData.f_name} onChange={handleChange} placeholder="First Name" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-6">
                        <input type="text" name="l_name" value={formData.l_name} onChange={handleChange} placeholder="Last Name" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-6">
                        <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-6">
                        <input type="number" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone Number" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-3">
                        <input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="City" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-3">
                        <input type="text" name="state" value={formData.state} onChange={handleChange} placeholder="State" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-3">
                        <input type="text" name="suburb" value={formData.suburb} onChange={handleChange} placeholder="Suburb" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-3">
                        <input type="number" name="post_code" value={formData.post_code} onChange={handleChange} placeholder="Post Code" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-4">
                        <input type="text" name="street_address" value={formData.street_address} onChange={handleChange} placeholder="Street Address" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-4">
                        <input type="text" name="address_type" value={formData.address_type} onChange={handleChange} placeholder="Address Type" className="form-control mb-3" required/>
                    </div>
                    <div className="col-md-4">
                        <input type="text" name="company" value={formData.company} onChange={handleChange} placeholder="Company (Optional)" className="form-control mb-3" />
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="profile-edit-btn border-0">{submitBtnText}</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Address;
