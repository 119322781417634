import React, { useState } from "react";
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet-async";

const envConstants = env_constants;

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [submitBtnText, setSubmitBtnText] = useState('Send OTP');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = async (event) => {
        setSubmitBtnText('Sending OTP...');
        event.preventDefault();
        try {
            const response = await axios.post(`${env_constants.API_BASE_URL}/check-email`, { email });
            toast.success('OTP sent to email');

            // Store email in session storage
            sessionStorage.setItem('email', email);

            // Set timeout to remove email after 5 minutes (300000 milliseconds)
            setTimeout(() => {
                sessionStorage.removeItem('email');
            }, 300000);

            navigate('/otp');
        } catch (error) {
            toast.error(error.response.data.message || 'An error occurred. Please try again.');
            setSubmitBtnText('Send OTP');
        }
    };

    return (
        <div className="container p-0 p-sm-2 my-5">
            <Helmet>
                <title>Forgot Password - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Forgot Password" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="new account yazi, sign up yazi" />
            </Helmet>
            <div className="p-2">
                <div className="h4">Forgot Your Password?</div>
                <hr />
                <form onSubmit={handleSubmit} className="p-2 p-md-4 border">
                    <div className="">
                        <div className="col-md-8 row m-auto">
                            <div className="col-12 form-group mb-3">
                                <label className="form-label" htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    id="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group mb-3 text-center">
                                <button type="submit" className="shop-btn">{submitBtnText}</button>
                            </div>
                            <div className="col-12 form-group mb-3 text-center">
                                <p>Return to <Link to="/signin" className="text-decoration-none text-danger text-dark-hover">Sign In</Link></p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgetPassword;
