import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import env_constants from '../Constants';
import axios from '../Axiosapi';
import { AuthContext } from '../Context/AuthContext';
import { Helmet } from 'react-helmet-async';
import envConstants from '../Constants';


const TrackOrder = () => {
    const [orderId, setOrderId] = useState('');
    const [orderStatus, setOrderStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [orderItems, setOrderItems] = useState([]);
    const { user } = useContext(AuthContext);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramOrderId = searchParams.get('order_id');

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent form default submission
        setLoading(true);
        try {
            const response = await axios.get(`${env_constants.API_BASE_URL}/orders-detail`, {
                params: { id: orderId },
            });
            setOrderData(response.data.data);
            setOrderItems(response.data.data.order_meta);
        } catch (error) {
            setError(error);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchOrderDetails = async (pOrderId) => {
        setLoading(true);
        try {
            const response = await axios.get(`${env_constants.API_BASE_URL}/orders-detail`, {
                params: { id: pOrderId },
            });
            setOrderData(response.data.data);
            setOrderItems(response.data.data.order_meta);
        } catch (error) {
            setError(error);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (paramOrderId) {
            fetchOrderDetails(paramOrderId);
        }
    }, [paramOrderId]);

    return (
        <>
        <Helmet>
            <title>Order Details - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
            <meta name="description" content="Track Order by entering your invoice number" />
            <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
            <meta name="keywords" content="track order, order track, order track yazi, yazi track order" />
        </Helmet>
            <div className="container my-5">
                <div className="row mb-3 d-none">
                    <div className="col-12">
                        <h1 className="heading-1 text-center">Track Order</h1>
                        <p className="text-center">Enter the invoice number to track the order. For example your invoice is <b>INV-OS-0123</b>, then your invoice number is <b>12345</b></p>
                    </div>
                </div>
                <div className="row d-none">
                    <div className="col-12">
                        <form className='d-block d-md-flex justify-content-center align-items-center' onSubmit={handleSubmit}>
                            <label htmlFor="order_id">Enter Invoice Number: &nbsp;</label>
                            <input
                                type="text"
                                className="form-control mx-0 mx-md-2 my-3 my-md-0"
                                style={{ width: '300px' }}
                                id="order_id"
                                value={orderId}
                                onChange={(event) => setOrderId(event.target.value)}
                            />
                            <button type="submit" className="btn btn-danger" disabled={loading}>
                                {loading ? 'Loading...' : 'Track Order'}
                            </button>
                        </form>
                    </div>
                </div>
                {orderData && (
                    <div className="row my-5">
                        <h2 className="h3 mb-3">Order Details</h2>
                        <hr />
                        <div className="col-12 col-sm-6 col-md-4 pb-md-0 pb-3">
                            <p className='h6 text-danger'><strong>Invoice Details:</strong></p>
                            <p className='m-0'><strong>Invoice:</strong> <b className='text-danger'>#{orderData.invoice_number}</b></p>
                            <p className='m-0'><strong>Order Status:</strong> {orderData.status}</p>
                            <p className='m-0'><strong>Date:</strong> {new Date(orderData.created_at).toLocaleDateString()}</p>
                            <hr width="70%" />
                            <p className='h6 text-danger'><strong>Payment Details:</strong></p>
                            <p className='m-0'><strong>Payment Method:</strong> {orderData.payment_method}</p>
                            <p className='m-0'><strong>Payment Status:</strong> {orderData.payment_status}</p>
                        </div>
                        {
                            user ? (
                        <div className='col-12 col-sm-6 col-md-4 mb-3'>
                            <p className='h6 text-danger'><strong>Customer Details:</strong></p>
                            <p className='m-0'>{orderData.f_name} {orderData.l_name}</p>
                            <p className='m-0'>{orderData.email}</p>
                            <p className='m-0'>{orderData.phone}</p>
                        </div>
                        )
                        : null}
                        <div className='col-12 col-sm-6 col-md-4 mb-3'>
                            <p className='h6 text-danger'><strong>Shipping Details:</strong></p>
                            <p className='m-0'>{orderData.address}</p>
                            <p className='m-0'>{orderData.street_address}</p>
                            <p className='m-0'>{orderData.city}, {orderData.state}, {orderData.post_code}</p>
                        </div>
                    </div>
                )}
                {orderItems.length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <h2 className="h4">Order Items</h2>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th className='text-center'>Quantity</th>
                                        <th className='text-center'>Price</th>
                                        <th className='text-end'>Item Total (Discount Included)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderItems.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.product_title}</td>
                                            <td className='text-center'>{item.quantity}</td>
                                            <td className='text-center'>${item.price}</td>
                                            <td className='text-end'>${item.qp_subtotal}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
                {orderData && (
                    <div className="row my-1">
                        <div className="col-12 py-1">
                            <p className='text-end'>
                                <strong>Subtotal: </strong> ${orderData.subTotal}<br/>
                                <strong>Shipping: </strong> ${orderData.delivery_charges}<br/>
                                <strong>GST: </strong> ${orderData.totalGst}
                            </p>
                            <p className='h2 text-end'><strong>Total Amount:</strong> ${orderData.total_amount}</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default TrackOrder;
