import React, { useState, useEffect } from "react";
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet-async";

const envConstants = env_constants;

const OTP = () => {
    const [otp, setOtp] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [submitBtnText, setSubmitBtnText] = useState('Confirm OTP');

    useEffect(() => {
        const storedEmail = sessionStorage.getItem('email');
        if (storedEmail) {
            setEmail(storedEmail);
        } else {
            // Redirect to forgot password if email is not in session storage
            navigate('/forgot-password');
        }
    }, [navigate]);

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSubmit = async (e) => {
        setSubmitBtnText('Confirming OTP...');
        e.preventDefault();
        try {
            const response = await axios.post(`${env_constants.API_BASE_URL}/confirm-otp`, { otp, email });
            setMessage(response.data.message);
            toast.success('OTP Confirmed');
            navigate('/reset-password');

            // Store OTP in session storage
            sessionStorage.setItem('otp', otp);

            // Set timeout to remove OTP after 5 minutes (300000 milliseconds)
            setTimeout(() => {
                sessionStorage.removeItem('otp');
            }, 300000);

            // Handle success response (e.g., navigate to the next page, show success message)
        } catch (error) {
            toast.error(error.response ? error.response.data.message : "An error occurred. Please try again.");
            setSubmitBtnText('Confirm OTP');
        }
    };

    return (
        <div className="container p-0 p-sm-2 my-5">
            <Helmet>
                <title>Confirm OTP - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="reset password otp" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="new account yazi, sign up yazi" />
            </Helmet>
            <div className="p-2">
                <div className="h4">Confirm OTP</div>
                <hr />
                <form className="p-2 p-md-4 border" onSubmit={handleSubmit}>
                    <div className="">
                        <div className="col-md-8 row m-auto">
                            <div className="col-12 form-group mb-3">
                                <label className="form-label" htmlFor="otp">OTP</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="otp"
                                    id="otp"
                                    value={otp}
                                    onChange={handleOtpChange}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group mb-3 text-center">
                                <button type="submit" className="shop-btn">{submitBtnText}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OTP;
