import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import envConstants from "../Constants";

const AboutUs = () => {

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (
        <div className="container p-sm-2 my-5">
            <Helmet>
                <title>About Us - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Our core philosophy is simple: to be the leading industrial tool company in Australia. We achieve this by deeply valuing our customers, suppliers, and staff. We offer an extensive range of industrial tools at competitive prices, reinvesting our profits into the business to maintain low overheads and attract top talent." />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="yazi about, about yazi" />
            </Helmet>
            <h2 className="pb-3 pb-md-5 text-center">About Us</h2>
            <p>
                Thank you for choosing Yazi. Since our humble beginnings as a small two-person store on the Bayside, we've remained a privately owned company. Many of our team members have been with us for years, with some becoming shareholders. This stability is reflected in the familiar faces you see in all our stores, a testament to our well-run business.
            </p><p>
                Our core philosophy is simple: to be the leading industrial tool company in Australia. We achieve this by deeply valuing our customers, suppliers, and staff. We offer an extensive range of industrial tools at competitive prices, reinvesting our profits into the business to maintain low overheads and attract top talent.
            </p><p>
                Unlike many in our industry, we do not franchise. We believe this allows us to consistently offer superior products and service without compromise.
            </p><p>
                At Yazi, we proudly offer unique products you won't find elsewhere, including our renowned Renegade Industrial air compressors manufactured locally in Queensland. We also import a wide range of machinery, power tools, hand tools, and accessories, and handle warranties directly for prompt service.
            </p><p>
                With our commitment to ethical service and comprehensive product knowledge, Yazi stands out as a leader in the Australian tool market.
            </p><p>
                Thank you for your continued support. We look forward to serving you for years to come.
            </p>
        </div>
    );
};

export default AboutUs;