import { Helmet } from "react-helmet-async";
import envConstants from "../Constants";
const ShippingOption = () => {
    return (
        <div className="container my-4">
            <Helmet>
                <title>Shipping Option - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Our core philosophy is simple: to be the leading industrial tool company in Australia. We achieve this by deeply valuing our customers, suppliers, and staff. We offer an extensive range of industrial tools at competitive prices, reinvesting our profits into the business to maintain low overheads and attract top talent." />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="yazi shipping option, shipping option yazi" />
            </Helmet>
            <h2>Shipping Option</h2>
            <p>At Yazi Auto, we offer a variety of shipping options to ensure that your order arrives in a timely manner. Whether you need your automotive accessories quickly or are looking for a more cost-effective option, we’ve got you covered.</p>
            <h5>Standard Shipping</h5>
            <p>Our standard shipping option is perfect for those who are not in a hurry to receive their order. With this option, your items will typically be delivered within 3-5 business days. Please note that delivery times may vary depending on your location.</p>
            <h5>Order Tracking</h5>
            <p>
                Once you have completed your purchase online, we will send you an email confirmation to acknowledge your order. Additionally, we will provide you with a tracking number once your items have been dispatched from our warehouse. You can use this tracking number to monitor the progress of your order and estimate its arrival time.<br /><br />
                To track your order, simply log in to your account on our website and view the details under “My Orders.” If you don’t have an account, you can still track your order using your email address and order number on our “Track Order” page.<br /> <br />
                If you transacted as a guest, you can track your order via the links in the emails sent to you.
            </p>
            <h5>Important Notes</h5>
            <ul>
                <li>The estimated arrival times provided are based on information from our shipping partners. While we strive to provide accurate estimates, please note that these are only estimates and actual delivery times may vary.</li>
                <li>All products are shipped from our warehouse, ensuring quick and efficient processing.</li>
                <li>In the rare event that your items are missing or delayed, please allow 25 working days for us to trace, replace, or refund lost items.</li>
                <li>For high-value orders, regular mail service may not be available. In such cases, we will apply an express shipping method to ensure the safe delivery of your items.</li>
                <li>Our stock messages are regularly updated to provide accurate information about delivery timeframes. However, occasional delays may occur in areas affected by local lockdowns. We are closely monitoring the situation with our local couriers to ensure smooth and efficient service.</li>
            </ul>
            <p>We appreciate your understanding and patience during any unforeseen circumstances that may affect delivery times. If you have any further questions or concerns regarding shipping options or order tracking, please don’t hesitate to reach out to our customer support team.</p>
            <p>
                Yazi Auto Pty Ltd<br/>
                Australia<br/>
                <a href="tel:0400023444" className="text-decoration-none text-dark">0400023444</a><br/>
                <a href="mailto:sales@yazi.com.au" className="text-decoration-none text-dark">sales@yazi.com.au</a>
            </p>
        </div>
    )
}

export default ShippingOption;