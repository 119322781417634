import React from "react";

function Reviews() {
  return (
    <>
      <div className="products-section-title">
        <h2 className="title">Reviews</h2>
      </div>
      <div className="tabs">
        <div
          id="new_comment_form_ok"
          className="alert alert-success"
          style={{ display: "none", padding: "15px 25px" }}
        ></div>
        <div id="product_comments_block_tab">
          <div
            className="comment clearfix"
            itemProp="review"
            itemScope
            itemType="https://schema.org/Review"
          >
            <div className="comment_author">
              <span className="span_color_grade">Grade&nbsp;</span>
              <div class="star-rating">
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star gray">&#9733;</span>
                <span class="star gray">&#9733;</span>
              </div>

              <div className="criterions_grade span_color">
                Quality
                <br />
                <div class="star-rating">
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star">&#9733;</span>
                  <span class="star gray">&#9733;</span>
                  <span class="star gray">&#9733;</span>
                </div>
              </div>
              <div className="comment_author_infos">
                <span className="span_color">
                  admin t
                  <br />
                </span>
                <em>04/28/2023</em>
                <meta content="04/28/2023" />
              </div>
            </div>
            <div className="comment_details">
              <p className="title_block font_size m-0">
                Variable Speed Orbital
              </p>
              <p className="title_block font_size">
                A jigsaw is a versatile and indispensable
              </p>
            </div>
          </div>
        </div>
        <div className="clearfix pull-right ">
          <a
            className="open-comment-form form_btn_color"
            href="#new_comment_form"
          >
            WRITE YOUR REVIEW
          </a>
        </div>
      </div>
    </>
  );
}

export default Reviews;
