import { Helmet } from "react-helmet-async";
import envConstants from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const TooManyRequest = () => {
    const [showButton, setShowButton] = useState(false);
    const [timer, setTimer] = useState(35);
    const toomanyrequest = '/assets/images/toomanyrequest.png';
    const navigate = useNavigate();

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    setShowButton(true);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000); // Set interval to 1 second (1000 milliseconds)

        const redirectTimeout = setTimeout(() => {
            navigate('/');
        }, 45000); // Redirect after 45 seconds

        return () => {
            clearInterval(countdown);
            clearTimeout(redirectTimeout);
        };
    }, [navigate]);

    return (
        <>
            <Helmet>
                <title>Too many requests - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Too many requests" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="Too many requests at yazi" />
            </Helmet>
            <div style={{ height: "100vh", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className="text-center">
                    <img src={toomanyrequest} style={{ width: "20%", margin: "20px 0px 0px" }} />
                    <h5 className="text-danger">Excessive requests detected</h5>
                    <div style={{ height: "200px" }} className="d-flex align-items-center justify-content-center">
                        {showButton ? (
                            <Link to="/" className="btn btn-danger">Go to Homepage</Link>
                        ) : (
                            <p>You'll be able to access the website in {timer} seconds...</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TooManyRequest;
