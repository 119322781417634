import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { AuthContext } from '../Context/AuthContext';
import ProductCard from '../Components/ProductCard';

const { API_BASE_URL } = env_constants;

const SearchResults = () => {
    const location = useLocation();
    const { token } = useContext(AuthContext);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const resultsPerPage = 15;
    const query = new URLSearchParams(location.search).get('query');

    useEffect(() => {
        const fetchSearchResults = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/search-product`, {
                    params: { search: query },
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.data.data) {
                    setSearchResults(response.data.data);
                } else {
                    setSearchResults([]);
                }
            } catch (error) {
                setError('Error fetching search results');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        if (query) {
            fetchSearchResults();
        }
    }, [query, token]);

    const totalPages = Math.ceil(searchResults.length / resultsPerPage);
    const currentResults = searchResults.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const renderPagination = () => {
        const paginationItems = [];

        if (totalPages <= 5) {
            for (let i = 1; i <= totalPages; i++) {
                paginationItems.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <button className="search-result-pag-btn" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }
        } else {
            paginationItems.push(
                <li key={1} className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
                    <button className="search-result-pag-btn" onClick={() => handlePageChange(1)}>
                        1
                    </button>
                </li>
            );

            if (currentPage > 3) {
                paginationItems.push(
                    <li key="dots1" className="page-item disabled d-flex align-items-center">
                        <span className="search-result-pag-btn">...</span>
                    </li>
                );
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(totalPages - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                paginationItems.push(
                    <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                        <button className="search-result-pag-btn" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }

            if (currentPage < totalPages - 2) {
                paginationItems.push(
                    <li key="dots2" className="page-item disabled d-flex align-items-center">
                        <span className="search-result-pag-btn">...</span>
                    </li>
                );
            }

            paginationItems.push(
                <li key={totalPages} className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                    <button className="search-result-pag-btn" onClick={() => handlePageChange(totalPages)}>
                        {totalPages}
                    </button>
                </li>
            );
        }

        return paginationItems;
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="container my-5 search-results-page">
            <h2 className='mb-3'>Search Results for "{query}"</h2>
            {searchResults.length === 0 ? (
                <p>No results found.</p>
            ) : (
                <>
                    <div className="d-flex flex-wrap" style={{ gap: '10px' }}>
                        {currentResults.map((result, index) => (
                            <ProductCard 
                                key={index} 
                                product={result} 
                                categories={[]} 
                                badge={result.badge} 
                            />
                        ))}
                    </div>
                    <nav aria-label="Page navigation example" className="mt-4">
                        <ul className="pagination justify-content-center">
                            {renderPagination()}
                        </ul>
                    </nav>
                </>
            )}
        </div>
    );
};

export default SearchResults;
