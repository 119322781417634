import React, { useEffect, useState, useCallback } from "react";
import envConstants from "../Constants";
import { Link } from 'react-router-dom';
import axios from "../Axiosapi";
import { toast } from 'react-toastify';

const Footer = () => {
    const [categories, setCategories] = useState([]);
    const [errorCategories, setErrorCategories] = useState(null);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [email, setEmail] = useState("");
    const [loadingSubscribe, setLoadingSubscribe] = useState(false);

    const fetchCategories = useCallback(async () => {
        try {
            const response = await axios.get(`${envConstants.API_BASE_URL}/categories`);
            setCategories(response.data.data);
            setErrorCategories(null); // Reset error state
        } catch (error) {
            setErrorCategories(error);
            console.error(error);
        } finally {
            setLoadingCategories(false);
        }
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    const handleSubscribe = async (e) => {
        e.preventDefault();
        setLoadingSubscribe(true);
        try {
            await axios.post(`${envConstants.API_BASE_URL}/subscribe`, { email });
            toast.success("Subscribed successfully!");
            setEmail("");
        } catch (error) {
            toast.error("Subscription failed. Please try again.");
            console.error(error);
        } finally {
            setLoadingSubscribe(false);
        }
    };

    return (
        <>
            <div className="newsletter">
                <div className="container">
                    <div className="row row-col-2">
                        <div className="col p-0">
                            <div className="d-flex">
                                <div className="mesage-icon"></div>
                                <div className="ps-3">
                                    <h4 className="heading-5">Join Our Newsletter, Get 10% Off</h4>
                                    <p className="text">Get all latest information on events, sales and offers</p>
                                </div>
                            </div>
                        </div>
                        <div className="col p-0">
                            <form onSubmit={handleSubscribe}>
                                <div className="position-relative newsletter-form">
                                    <input 
                                        type="email" 
                                        placeholder="Your email address" 
                                        className="newsletter-field" 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <button 
                                        type="submit" 
                                        className="subscribe-btn" 
                                        disabled={loadingSubscribe}
                                    >
                                        {loadingSubscribe ? "Subscribing..." : "Subscribe"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="footer">
                    <div className="row row-cols-5">
                        <div className="col p-0">
                            <h4 className="heading-6">Contact Us</h4>
                            <div className="tab">
                                <input type="checkbox" name="accordion-1" id="cb1" />
                                <label htmlFor="cb1" className="tab__label">Store Information</label>
                                <div className="tab__content">
                                    <ul className="footer-list">
                                        <li className="address">
                                            Yazi Auto Pty Ltd<br />
                                            Australia
                                        </li>
                                        <li><a href="tel:0400023444">0400023444</a></li>
                                        <li><a href="mailto:sales@yazi.com.au">sales@yazi.com.au</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab">
                                <input type="checkbox" name="accordion-2" id="cb2" />
                                <label htmlFor="cb2" className="tab__label">Follow Us</label>
                                <div className="tab__content">
                                    <ul className="social-icon d-flex gap-3">
                                        <li><a href="/" className="facebook"></a></li>
                                        <li><a href="/" className="twitter"></a></li>
                                        <li><a href="/" className="rss"></a></li>
                                        <li><a href="/" className="youtube"></a></li>
                                        <li><a href="/" className="pinterest"></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col p-0">
                            <h4 className="heading-6">Products</h4>
                            <div className="tab">
                                <input type="checkbox" name="accordion-3" id="cb3" />
                                <label htmlFor="cb3" className="tab__label">Products</label>
                                <div className="tab__content">
                                    <ul className="footer-list">
                                        <li><a href="/" className="footer-list-link">Prices Drop</a></li>
                                        <li><a href="/" className="footer-list-link">New Products</a></li>
                                      {/* <li><a href="/" className="footer-list-link">Best Sales</a></li> */} 
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col p-0">
                            <h4 className="heading-6">Our Company</h4>
                            <div className="tab">
                                <input type="checkbox" name="accordion-4" id="cb4" />
                                <label htmlFor="cb4" className="tab__label">Our Company</label>
                                <div className="tab__content">
                                    <ul className="footer-list">
                                        <li><Link to="/about-us" className="footer-list-link">About Us</Link></li>
                                        <li><Link to="/privacy-and-policy" className="footer-list-link">Privacy Policy</Link></li>
                                        <li><Link to="/feedback" className="footer-list-link">Feedback</Link></li>
                                        <li><Link to="/shipping-option" className="footer-list-link">Shipping Option</Link></li>
                                        <li><Link to="/return-policy" className="footer-list-link">Return Policy</Link></li>
                                        <li><Link to="/terms-conditions" className="footer-list-link">Terms & Conditions</Link></li>
                                        <li><Link to="/editorial-guidelines" className="footer-list-link">Editorial Guidelines</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col p-0">
                            <h4 className="heading-6">Information</h4>
                            <div className="tab">
                                <input type="checkbox" name="accordion-5" id="cb5" />
                                <label htmlFor="cb5" className="tab__label">Information</label>
                                <div className="tab__content">
                                    <ul className="footer-list">
                                        <li className="d-none"><Link to="/track-order" className="footer-list-link">Track Order</Link></li>
                                        <li><Link to="/contact-us" className="footer-list-link">Contact Us</Link></li>
                                        <li><Link to="/return-order" className="footer-list-link">Return</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col p-0">
                            <h4 className="heading-6">Trending Category</h4>
                            <div className="tab">
                                <input type="checkbox" name="accordion-1" id="cb6" />
                                <label htmlFor="cb6" className="tab__label">Trending Category</label>
                                <div className="tab__content">
                                    <ul className="footer-list">
                                        {loadingCategories ? (
                                            <div className="d-flex justify-content-center align-items-center p-5">
                                                <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        ) : errorCategories ? (
                                            <div className="d-flex justify-content-center">Error loading categories and brands: {errorCategories.message}</div>
                                        ) : (
                                            categories.slice(1, 7).map((category) => (
                                                <li key={category.sku}><Link to={`/shop?category=${category.sku}&page=1`} className="footer-list-link">{category.title}</Link></li>
                                            ))
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="footer-bottom">
                <div className="container">
                    <div className="row row-col-2">
                        <div className="col p-0"><p className="website-made">© 2024 - Developed By <a className="text-decoration-none text-danger" href="https://ictechnology.com.au/" target="_blank">ICTechnology, Australia</a></p></div>
                        <div className="col p-0"><img src="https://prestashop.coderplace.com/PRS02/PRS02044/demo/modules/cp_footercms1/views/img/payment.png" className="payments-cards" alt="Payment Methods" /></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
