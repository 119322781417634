import { Helmet } from "react-helmet-async";
import envConstants from "../Constants";
const Feedback = () => {
    return (
        <div className="container my-4">
            <Helmet>
                <title>Feedback - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Our core philosophy is simple: to be the leading industrial tool company in Australia. We achieve this by deeply valuing our customers, suppliers, and staff. We offer an extensive range of industrial tools at competitive prices, reinvesting our profits into the business to maintain low overheads and attract top talent." />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="yazi Feedback, Feedback yazi" />
            </Helmet>
            <h2>Feedback & Complaints</h2>
            <p>At Yazi Auto, we greatly appreciate your feedback as it plays a crucial role in helping us enhance our services. We welcome both positive comments and any concerns or complaints you may have. If you would like to provide feedback or raise a complaint, please choose from the following options:</p>
            <h5>Write to Us</h5>
            <p>You can send us an email at <a href="mailto:sales@yazi.com.au" className="text-decoration-none text-dark fw-bold">sales@yazi.com.au</a> with your feedback or complaint.</p>
            <h5>Online Form</h5>
            <p>Feel free to use our <a href="/contact-us" className="text-decoration-none text-dark fw-bold">Contact Us</a> form online to submit your feedback.
                <br />
                <br />
                When reaching out, please include enough details for us to identify you, such as your account information or order number. Clearly explain the subject of your complaint or feedback, and let us know how you would like your concern to be addressed.
            </p>
            <h5>Our Process</h5>
            <p>Once we receive your feedback or complaint, we will take the following steps:</p>
            <ol>
                <li>Acknowledge receipt of your communication within 3 business days.</li>
                <li>Contact you for any additional information, if necessary.</li>
                <li>Thoroughly assess and investigate your concerns.</li>
                <li>Strive to resolve your complaint fairly and objectively.</li>
                <li>Aim to provide a resolution within 30 days.</li>
                <li>Inform you of our decision and the reasons behind it.</li>
            </ol>
            <p>
                Our goal is to address your complaint to your satisfaction and enhance your experience with Yazi Auto.
                <br />
                <br />
                We appreciate your input and assure you that we will do our best to respond promptly and effectively.
            </p>
        </div>
    )
}

export default Feedback;