import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { AuthContext } from '../Context/AuthContext';
import { toast } from 'react-toastify';

const UpdateAddress = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [address, setAddress] = useState(null);
    const [submitBtnText, setSubmitBtnText] = useState('Update Address');

    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                const response = await axios.get(`${env_constants.API_BASE_URL}/user-addresses`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const addresses = response.data.data.shipping_addresses;
                const foundAddress = addresses.find(addr => addr.id === parseInt(id));
                setAddress(foundAddress);
                console.log(foundAddress);
            } catch (error) {
                console.error('Error fetching addresses:', error.response ? error.response.data : error.message);
                toast.error('Failed to fetch address details.');
            }
        };
        console.log(token);

        if (token) {
            fetchAddresses();
        }
    }, [token, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddress(prevAddress => ({
            ...prevAddress,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitBtnText(' Updating Address... ');
        try {
            await axios.put(`${env_constants.API_BASE_URL}/user-addresses/${id}`, address, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            toast.success('Address updated successfully!');
            navigate('/my-account');
        } catch (error) {
            console.error('Error updating address:', error.response ? error.response.data : error.message);
            toast.error('Failed to update address.');
            setSubmitBtnText('Update Address');
        }
    };

    if (!address) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container my-5">
            <h2>Update Address</h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <input
                            type="text"
                            name="f_name"
                            placeholder="First Name"
                            className="form-control"
                            value={address.f_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <input
                            type="text"
                            name="l_name"
                            placeholder="Last Name"
                            className="form-control"
                            value={address.l_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            className="form-control"
                            value={address.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <input
                            type="text"
                            name="phone"
                            placeholder="Phone"
                            className="form-control"
                            value={address.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-3 mb-3">
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            className="form-control"
                            value={address.city}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-3 mb-3">
                        <input
                            type="text"
                            name="state"
                            placeholder="State"
                            className="form-control"
                            value={address.state}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-3 mb-3">
                        <input
                            type="text"
                            name="suburb"
                            placeholder="Suburb"
                            className="form-control"
                            value={address.suburb}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-3 mb-3">
                        <input
                            type="text"
                            name="post_code"
                            placeholder="Post Code"
                            className="form-control"
                            value={address.post_code}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4 mb-3">
                        <input
                            type="text"
                            name="street_address"
                            placeholder="Street Address"
                            className="form-control"
                            value={address.street_address}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4 mb-3">
                        <input
                            type="text"
                            name="address_type"
                            placeholder="Address Type"
                            className="form-control"
                            value={address.address_type}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-4 mb-3">
                        <input
                            type="text"
                            name="company"
                            placeholder="Company"
                            className="form-control"
                            value={address.company}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-12">
                        <button type="submit" className="profile-edit-btn border-0">{submitBtnText}</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UpdateAddress;
