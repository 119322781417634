import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../Axiosapi';
import envConstants from '../Constants';
import Tabs from "../Components/SingleProductDescription";
import ProductDetail from "../Components/SingleProductDetail";
import ProductImage from "../Components/SingleProductSlider";
import Reviews from "../Components/ProductReview";
import ProductSlider from "../Components/Sub-Components/ProductSlider";
import { useCart } from '../Context/CartContext'; // Import useCart hook
import { Helmet} from "react-helmet-async";

const ProductPageDetail = () => {
    const { sku } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);
    const { dispatch } = useCart(); // Access dispatch from useCart

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/categories`);
                setCategories(response.data.data); // Update to use response.data.data
            } catch (error) {
                setError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchLatestProducts = async ({ pagination = 20, json = false } = {}) => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/latest-products`, {
                    params: { pagination },
                    responseType: json ? 'json' : 'json',
                });
                setLatestProducts(response.data.data.data); // Correctly accessing the nested data array
            } catch (error) {
                setError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };        

        fetchCategories();
        fetchLatestProducts();

    }, []);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/products/${sku}`);
                setProduct(response.data.data);
            } catch (error) {
                setError(error);
                console.error('Error fetching product:', error.response ? error.response.data : error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [sku]);

    const badrequest = '/assets/images/bad-request.png';

    if (loading) return <div>Loading...</div>;
    if (error) return <div className='text-center'><img src={badrequest} style={{width: "40%", margin: "20px 0px"}}/></div>;

    return (
        <div className="container p-sm-2">
            <Helmet>
                <title>{product &&  (product?.product_meta?.find(meta => meta.option === '_seo_title')?.value ||  product?.title )} - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content={product &&  (product?.product_meta?.find(meta => meta.option === '_seo_description')?.value ||  product?.title )} />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content={product &&  (product?.product_meta?.find(meta => meta.option === '_seo_tags')?.value ||  envConstants.STORE_META_KEYWORDS )} />
            </Helmet>
           
            <div className="d-flex mt-3 mb-3" style={{ width: '100%' }}>
                <div className="row" style={{ width: '100%', margin: 'auto' }}>
                    <div className="col-12 col-md-5 mb-3 mb-md-0 product-detail-container">
                        <ProductImage 
                            galleryMedia={product && product.gallery_media} // Check if product exists before accessing gallery_media
                            featuredImage={product && product.featured_image} // Pass featured_image prop
                        /> 
                    </div>
                    <div className="col-12 col-md-7 border-start">
                        <ProductDetail product={product} dispatch={dispatch} /> {/* Pass dispatch prop */}
                    </div>
                </div>
            </div>
            <div>
                <Tabs product={product} /> {/* Pass product prop */}
            </div>
            <div className="porduct-section">
                <h2 className="heading-2">New Products</h2>
                <p className="theme-text">Check out our collection of the top New Products that encourage conversion.</p>
                <ProductSlider products={latestProducts} categories={categories} badge="New"/>
            </div>  
            <div>
              {/*  <Reviews />*/}
            </div>
        </div>
    );
};

export default ProductPageDetail;