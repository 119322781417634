import React, { createContext, useState, useEffect } from 'react';
import axios from '../Axiosapi';
import envConstants from '../Constants';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const sessionTimeout = 30 * 60 * 1000; // 30 minutes in milliseconds

    const handleLogout = () => {
        setUser(null);
        setToken(null);
        setSubscription(null);
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('subscription');
        sessionStorage.removeItem('timestamp');
        delete axios.defaults.headers.common['Authorization'];
    };

    const resetSessionTimer = () => {
        clearTimeout(timer);
        timer = setTimeout(handleLogout, sessionTimeout);
    };

    let timer = setTimeout(handleLogout, sessionTimeout);

    useEffect(() => {
        const storedUser = sessionStorage.getItem('user');
        const storedToken = sessionStorage.getItem('token');
        const storedSubscription = sessionStorage.getItem('subscription');
        const storedTimestamp = sessionStorage.getItem('timestamp');

        if (storedUser && storedToken && storedTimestamp) {
            const currentTime = new Date().getTime();
            if (currentTime - storedTimestamp < sessionTimeout) {
                setUser(JSON.parse(storedUser));
                setToken(storedToken);
                setSubscription(storedSubscription);
                axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
                window.addEventListener('click', resetSessionTimer);
                window.addEventListener('keypress', resetSessionTimer);
            } else {
                handleLogout();
            }
        }
    }, []);

    const login = async (email, password) => {
        try {
            const response = await axios.post(`${envConstants.API_BASE_URL}/login`, { email, password });
            if (response.data && response.data.token) {
                const { user, token, subscription } = response.data;
                setUser(user);
                setToken(token);
                setSubscription(subscription);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('subscription', JSON.stringify(subscription));
                sessionStorage.setItem('timestamp', new Date().getTime());
            } else {
                throw new Error('Invalid login response');
            }
        } catch (error) {
            throw error;
        }
    };

    const register = async (registrationData) => {
        try {
            const response = await axios.post(`${envConstants.API_BASE_URL}/registered`, registrationData);
            if (response.data && response.data.token) {
                const { user, token, subscription } = response.data;
                setUser(user);
                setToken(token);
                setSubscription(subscription);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                sessionStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('subscription', JSON.stringify(subscription));
                sessionStorage.setItem('timestamp', new Date().getTime());
            } else {
                throw new Error('Invalid registration response');
            }
        } catch (error) {
            throw error;
        }
    };

    const updateUserProfile = (updatedUser) => {
        setUser(updatedUser);
        sessionStorage.setItem('user', JSON.stringify(updatedUser));
    };

    return (
        <AuthContext.Provider value={{ user, token, subscription, login, logout: handleLogout, register, updateUserProfile }}>
            {children}
        </AuthContext.Provider>
    );
};
