import React, { useState, useEffect } from "react";
import envConstants from "../Constants";
import axios from "../Axiosapi";
import ProductCard from '../Components/ProductCard';
import { Helmet } from "react-helmet-async";


const BestSelling = () => {
  {/*  const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/categories`);
                setCategories(response.data.data); 
            } catch (error) {
                setError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchBestSellingProducts = async () => {
            try {
                const response = await axios.get(`${envConstants.API_BASE_URL}/best-seller-products`);
                console.log("Best Selling Products Response:", response.data); // Debugging
                setProducts(response.data.data || []);
            } catch (error) {
                setError(error);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
        fetchBestSellingProducts();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data</p>; */}

    return (
        <div className="container py-4">
            <Helmet>
                <title>Best Selling Products - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Featured, Most Selling, Best Selling" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="Best Selling Products" />
            </Helmet>
            <h2>Best Selling</h2>
         {/*   {products.length === 0 ? (
                <p className='text-center'>No Product Found</p>
            ) : (
                <div className="shop-product-list d-flex flex-wrap justify-content-start flex-wrap gap-2 gap-md-3 ps-md-1">
                    {products.map((product) => (
                        <ProductCard
                            key={product.id}
                            product={product}
                            categories={categories}
                            badge="Best Selling"
                            className="shop-product"
                        />
                    ))}
                </div>
            )}*/}
        </div>
    );
}

export default BestSelling;