import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useState, useCallback, useEffect } from 'react';
import ShopSidebar from "../ShopSidebar";
import envConstants from "../../Constants";
import axios from "../../Axiosapi";
import { Link } from 'react-router-dom';
import _ from 'lodash';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [errorCategories, setErrorCategories] = useState(null);
    const [brands, setBrands] = useState([]);

    // Debounce the toggle function to prevent rapid clicks from causing issues
    const ToggleSidebar = useCallback(
        _.debounce(() => {
            setIsOpen(prevState => !prevState);
        }, 300), // 300ms debounce delay
        []
    );

    const fetchCategories = useCallback(async () => {
        try {
            const response = await axios.get(`${envConstants.API_BASE_URL}/categories`);
            setCategories(response.data.data);
            setErrorCategories(null); // Reset error state
        } catch (error) {
            setErrorCategories(error);
            console.error(error);
        } finally {
            setLoadingCategories(false);
        }
    }, []);

    const fetchBrands = useCallback(async () => {
        try {
            const response = await axios.get(`${envConstants.API_BASE_URL}/brands`);
            setBrands(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchBrands();
        fetchCategories();
    }, [fetchCategories, fetchBrands]);

    return (
        <>
            <div className="navbar">
                <div className="container p-0 px-sm-2 position-relative">
                    <div className="d-flex main-navbar">
                        <div className="category-sidebar d-flex align-items-center">
                            <button className="all-category-btn" onClick={ToggleSidebar}>
                                <span className='pe-2'><FontAwesomeIcon icon={faBars} /></span> Browse Categories
                            </button>
                        </div>
                        <div className="navbar-items align-items-center">
                            <nav>
                                <ul className="nav-items">
                                    <li className="position-relative megamenu-one">
                                        <Link to="/shop?page=1">Shop</Link>
                                        <div className="megamenu">
                                            <div className="d-flex justify-content-between flex-wrap">
                                                <div className="megamenu-items">
                                                    <div className="row row-col-4">
                                                        {/* Megamenu categories */}
                                                        <div className="col">
                                                            <Link to="/shop?category=Sunshades-Steering-Wheel-Covers&page=1" className="megamenu-heading">Sunshades & Steering Wheel Covers</Link>
                                                            <ul className="megamenu-list">
                                                                <li><Link to="/shop?child_category=Sunshade-Mixed-Packs&page=1">Sunshade Mixed Packs</Link></li>
                                                                <li><Link to="/shop?child_category=Hub-Nut-Tools&page=1">Sunshade Mixed Packs</Link></li>
                                                                <li><Link to="/shop?child_category=Hub-Nut-Tools&page=1">Hub Nut Tools</Link></li>
                                                                <li><Link to="/shop?child_category=Hose-Clamps&page=1">Hose Clamps</Link></li>
                                                                <li><Link to="/shop?child_category=Window-Sock&page=1">Window Sock</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col">
                                                            <Link to="/shop?category=Wheel-Covers&page=1" className="megamenu-heading">Wheel Covers</Link>
                                                            <ul className="megamenu-list">
                                                                <li><Link to="/shop?child_category=Wheel-Trims&page=1">Wheel Trims</Link></li>
                                                                <li><Link to="/shop?child_category=Worklights&page=1">Worklights</Link></li>
                                                                <li><Link to="/shop?child_category=Wiring-Loom-Tape&page=1">Wiring Loom Tape</Link></li>
                                                                <li><Link to="/shop?child_category=Window-Winders&page=1">Window Winders</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col">
                                                            <Link to="/shop?category=Accessories&page=1" className="megamenu-heading">Accessories</Link>
                                                            <ul className="megamenu-list">
                                                                <li><Link to="/shop?child_category=Sunshade-Mixed-Packs&page=1">Sunshade Mixed Packs</Link></li>
                                                                <li><Link to="/shop?child_category=Back-Support&page=1">Back Support</Link></li>
                                                                <li><Link to="/shop?child_category=Seat-Cushions&page=1">Seat Cushions</Link></li>
                                                                <li><Link to="/shop?child_category=Ball-Joint--Separator-Tools&page=1">Ball Joint & Separator Tools</Link></li>
                                                                <li><Link to="/shop?child_category=Air-Dusters--Wash-Guns&page=1">Air Dusters & Wash Guns</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col">
                                                            <Link to="/shop?category=Tie-Down&page=1" className="megamenu-heading">Tie Down</Link>
                                                            <ul className="megamenu-list">
                                                                <li><Link to="/shop?child_category=Occy-Straps&page=1">Occy Straps</Link></li>
                                                                <li><Link to="/shop?child_category=Knifes&page=1">Knifes</Link></li>
                                                                <li><Link to="/shop?child_category=Inverters&page=1">Inverters</Link></li>
                                                                <li><Link to="/shop?child_category=Lace-Up-SWC&page=1">Lace Up SWC</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="megamenu-image">
                                                    <Link to="shop?category=Tools-&-Testing-Kits&page=1"><img src="https://prestashop.coderplace.com/PRS02/PRS02044/demo/img/c/3-0_thumb.jpg" alt="" /></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="position-relative megamenu-two">
                                        <Link to="/shop">All brands</Link>
                                        <div className="brand-megamenu">
                                            <ul className="brand-megamenu-list">
                                                {brands.map((brand) => (
                                                    <li key={brand.id}><Link to={`/shop?page=1&brand=${brand.permalink}`}>{brand.title}</Link></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </li>
                                    <li className=''>
                                    <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                    <li className=''>
                                    <Link to="/return-order">Return</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="d-none free-order-btn d-flex justify-content-end align-items-center">
                            <Link to="/">Free on order $50+</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`sidebar ${isOpen ? 'active' : ''}`}>
                <div className="sd-header">
                    <h4 className="mb-0 sidebar-heading">Menu</h4>
                    <button className="sidebar-close" onClick={ToggleSidebar}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className="sd-body">
                    {loadingCategories ? (
                        <div className="d-flex justify-content-center align-items-center p-5">
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : errorCategories ? (
                        <div className="d-flex justify-content-center">Error loading categories: {errorCategories.message}</div>
                    ) : (
                        <ShopSidebar categories={categories} brands={brands} onCategoryClick={ToggleSidebar} />
                    )}
                </div>
            </div>
            <div className={`sidebar-overlay ${isOpen ? 'active' : ''}`} onClick={ToggleSidebar}></div>
        </>
    );
};

export default Navbar;
