import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import env_constants from '../Constants';
import { useCart } from '../Context/CartContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';  // Import lodash

const ProductCard = ({ product, categories = [], badge, className }) => {
    const { dispatch } = useCart();

    const categoryTitle = categories.find(category => category.id === product.category_id)?.title;
    const priceObj = product.product_meta;
    let _price = priceObj?.find(meta => meta.option === '_price')?.value || 0;

    const discountValue = product.discount_data?.discount_value;
    const discountType = product.discount_data?.discount_type;

    let discountedPrice = _price;

    if (discountValue) {
        if (discountType === "0") {
            discountedPrice = _price - (_price * (discountValue / 100));
        } else if (discountType === "1") {
            discountedPrice = _price - discountValue;
        }
    }

    const backgroundImageStyle = {
        backgroundImage: `url(${env_constants.API_BASE_PUBLIC_URL}${product.featured_image}), url(${env_constants.API_BASE_PUBLIC_URL}${product.featured_image})`,
    };

    // Throttle the handleAddToCart function
    const handleAddToCart = useCallback(_.throttle(() => {
        const productWithDefaultQuantity = { ...product, quantity: 1 };
        dispatch({ type: 'ADD_TO_CART', payload: productWithDefaultQuantity });
        toast.success(`${product.title} added to cart!`, {
            position: "top-right",
            autoClose: 3000, // 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
                color: "black", 
                fontWeight: "bold"
            },
            toastStyle: {
                backgroundColor: "red", 
            },
            iconTheme: {
                primary: "red", 
            }
        });
    }, 1000), [product, dispatch]);  // Throttle to allow one execution per second

    return (
        <div className={`product-card position-relative ${className}`}>
            <span className="product-tag">{badge}</span>
            {discountValue && (
                <span className="product-sale-tag">
                    {discountType === "0" ? `-${discountValue}%` : `-$${discountValue}`}
                </span>
            )}
            <ul className="product-features">
                <li><a href="/" className="product-wishlist"><i></i></a></li>
            </ul>
            <div
                style={backgroundImageStyle}
                className="team-member-thumbnail"
            ></div>
            <h5 className="product-category">{categoryTitle}</h5>
            <h3 className="product-title">
                <Link to={`/product/${encodeURIComponent(product.permalink)}`}>{product.title}</Link>
            </h3>
            <p className="product-price">
                ${parseFloat(discountedPrice).toFixed(2)} 
                {discountValue && <span className="original-price">${parseFloat(_price).toFixed(2)}</span>}
            </p>
            <button className="add-cart-btn" type="button" onClick={handleAddToCart}>Add to Cart</button>
        </div>
    );
};

export default ProductCard;
<ToastContainer />
