import { Helmet } from "react-helmet-async";
import envConstants from "../Constants";
const TermsConditions = () => {
    return (
        <div className="container my-4">
            <Helmet>
                <title>Terms & Conditions - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Our core philosophy is simple: to be the leading industrial tool company in Australia. We achieve this by deeply valuing our customers, suppliers, and staff. We offer an extensive range of industrial tools at competitive prices, reinvesting our profits into the business to maintain low overheads and attract top talent." />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="yazi terms & conditions, terms & conditions yazi" />
            </Helmet>
            <h2>Terms & Conditions</h2>
            <h4>Introduction</h4>
            <p>Welcome to the Yazi Auto website (the “Site”). The following Terms of Service (the “Terms”) govern your access and use of the Site. By accessing or using the Site, you acknowledge that you have read, understood, and agree to comply with these Terms. If you do not agree with anything contained in these Terms, please do not use the Site.</p>
            <h4>Intellectual Property</h4>
            <p>All rights, title, and interest in the Site and its content, including but not limited to text, graphics, logos, images, audio clips, video clips, data compilations, software, and any other materials contained on the Site (“Content”), are the exclusive property of Yazi Auto or its licensors. The Content is protected by copyright, trademark, and other intellectual property laws. You may not modify, copy, reproduce, republish, upload, post, transmit, distribute, or create derivative works from the Content without prior written permission from Yazi Auto.</p>
            <h4>Use of the Site</h4>
            <ul>
                You may use the Site for lawful purposes only. You agree not to:
                <li>Violate any applicable laws or regulations;</li>
                <li>Interfere with or disrupt the operation of the Site or servers or networks connected to the Site;</li>
                <li>Engage in any fraudulent or deceptive activities;</li>
                <li>Impersonate any person or entity;</li>
                <li>Collect or store personal information about other users of the Site;</li>
                <li>Use any robot, spider, or other automated device or process to access the Site for any purpose without our express written permission;</li>
                <li>Transmit any viruses, worms, defects, Trojan horses, or other malicious code through the Site;</li>
                <li>Attempt to gain unauthorized access to any portion or feature of the Site or any other systems or networks connected to the Site;</li>
                <li>Engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Site.</li>
            </ul>
            <h4>Disclaimer of Warranties</h4>
            <p>The Site and its content are provided on an “as is” and “as available” basis without any warranties of any kind. Yazi Auto does not warrant that the Site will be uninterrupted or error-free, that defects will be corrected, or that the Site or its servers are free of viruses or other harmful components. Yazi Auto disclaims all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            <h4>Limitation of Liability</h4>
            <p>In no event shall Yazi Auto or its affiliates be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Site or its content. This limitation applies whether the alleged liability is based on contract, tort, negligence, strict liability, or any other legal theory.</p>
            <h4>Indemnification</h4>
            <p>You agree to indemnify and hold Yazi Auto and its affiliates harmless from and against any claims, liabilities, damages, losses, costs, or expenses arising out of or in connection with your use of the Site or its content.</p>
            <h4>Governing Law</h4>
            <p>These Terms are governed by and construed in accordance with the laws of Australia. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in Australia.</p>
            <h4>Contact Information</h4>
            <p>For any questions regarding these Terms, please contact us at <a href="mailto:sales@yazi.com.au" className="text-decoration-none text-dark fw-bold">sales@yazi.com.au.</a></p>
        </div>
    )
}

export default TermsConditions;