import { Helmet } from "react-helmet-async";
import envConstants from "../Constants";

const PrivacyPolicy = () => {

    return (
        <div className="container p-sm-2 my-5">
            <Helmet>
                <title>Privacy Policy - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Yazi Group and its affiliated businesses are committed to safeguarding your privacy and ensuring the security of your personal information. This commitment extends to all personally identifiable information we receive, such as your name, address, telephone number, email address, and any other details you provide. We uphold this commitment not only to comply with legal requirements but also to preserve the trust you place in us and to foster a lasting relationship with you. This policy outlines how we adhere to the National Privacy Principles (NPPs) as stipulated in the Privacy Act 1988 (Cth)" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="privacy policy, yazi privacy policy" />
            </Helmet>
            <h2 className="pb-3 pb-md-5 text-center">Privacy Policy Update for Yazi Group</h2>
            <p>
                Yazi Group and its affiliated businesses are committed to safeguarding your privacy and ensuring the security of your personal information. This commitment extends to all personally identifiable information we receive, such as your name, address, telephone number, email address, and any other details you provide. We uphold this commitment not only to comply with legal requirements but also to preserve the trust you place in us and to foster a lasting relationship with you. This policy outlines how we adhere to the National Privacy Principles (NPPs) as stipulated in the Privacy Act 1988 (Cth).
            </p>
            <ol>
                <li>Collection of Personal Information We collect personal and sensitive information necessary to fulfill our functions and activities lawfully and ethically. When we collect this information, we will:
                    <ul>
                        <li>Identify ourselves and explain why the information is being collected.</li>
                        <li>Specify with whom the information may be shared.</li>
                        <li>Provide you with access to the information we hold about you and inform you of our contact details.</li>
                        <li>Notify you of any legal obligations requiring us to collect such information.</li>
                        <li>Explain any significant consequences if you choose not to provide certain information. If we obtain your personal information from a third party, we will ensure you are informed in accordance with the NPPs.</li>
                    </ul>
                </li>
                <li>Use of Cookies and Web Tracking Our website may use cookies to enhance your browsing experience by customizing content based on your navigation patterns. You can adjust your browser settings to manage or disable cookies, although this may affect certain functionalities</li>
                <li>Use and Disclosure of Personal Information Yazi occasionally collects personal information as part of its business operations for purposes connected with:
                    <ul>
                        <li>Verifying your identity.</li>
                        <li>Providing goods and services to you or receiving goods or services from you.</li>
                        <li>Developing, maintaining, and improving our products, services, and business.</li>
                        <li>Contacting you via electronic messaging, mail, social media, phone, or other lawful means in accordance with the NPPs..</li>
                        <li>Addressing any issues or complaints regarding our relationship.</li>
                    </ul>
                </li>
            </ol>
            <p>In certain circumstances, we may use or disclose your personal information for secondary purposes, such as direct marketing, provided it is related to the primary purpose of collection, or with your consent. We ensure third parties handling your personal information maintain strict confidentiality and only use it for performing services on our behalf.</p>
            <ol start="4">
                <li>Data Quality We take reasonable steps to ensure the personal information we collect, use, or disclose is accurate, complete, and up-to-date.</li>
                <li>Data Security We implement reasonable measures to protect personal information from misuse, loss, unauthorized access, modification, or disclosure. If personal information is no longer required for legal purposes, we take steps to destroy or de-identify it securely.</li>
                <li>Openness This policy document clearly outlines our policies on managing personal information and is available to anyone upon request. We provide information about the types of personal information we hold, how it is used, and how we collect, hold, use, and disclose that information.</li>
                <li>Access and Correction In most cases, upon request, we provide access to personal information we hold about you, except where exceptions apply under the NPPs. We also take reasonable steps to correct any inaccuracies in your personal information upon request.</li>
                <li>Identifiers We do not adopt government-issued identifiers unless necessary for legal purposes.</li>
                <li>Anonymity Where lawful and feasible, you have the option not to identify yourself when engaging in transactions with us.</li>
                <li>Transborder Data Flows We do not transfer personal data outside Australia unless permitted under the Privacy Act, such as with your consent or for contractual obligations.</li>
                <li>Sensitive Information We do not collect sensitive information unless permitted under the NPPs and with your explicit consent.</li>
            </ol>
        </div>
    );
};

export default PrivacyPolicy;