import React, { useState, useCallback } from "react";
import CartProductCard from "../Components/CartProductCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../Context/CartContext';
import ShopSidebar from "../Components/ShopSidebar";
import { useEffect } from "react";
import envConstants from "../Constants";
import axios from "../Axiosapi";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Cart = () => {
    const { cart } = useCart();
    const [categories, setCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [errorCategories, setErrorCategories] = useState(null);
    const [brands, setBrands] = useState([]);

    const fetchCategories = useCallback(async () => {
        
        try {
            const response = await axios.get(`${envConstants.API_BASE_URL}/categories`);
            setCategories(response.data.data);
            setErrorCategories(null); // Reset error state
        } catch (error) {
            setErrorCategories(error);
            console.error(error);
        } finally {
            setLoadingCategories(false);
        }
    },[]);

    const totalPrice = cart.reduce((total, product) => {
        const priceMeta = product.product_meta.find(meta => meta.option === '_price');
        const originalPrice = priceMeta ? parseFloat(priceMeta.value) : 0;

        const discountData = product.discount_data;
        let finalPrice = originalPrice;
        
        if (discountData && discountData.discounted_price) {
            finalPrice = parseFloat(discountData.discounted_price);
        }

        return total + product.quantity * finalPrice;
    }, 0);
    const fetchBrands = useCallback(async () => {
        try {
            const response = await axios.get(`${envConstants.API_BASE_URL}/brands`);
            setBrands(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }, []);
    useEffect(() => {
        fetchCategories();
        fetchBrands();
    }, [fetchCategories,fetchBrands ]);
    return (
        <div className="cart-section">
            <Helmet>
                <title>Cart - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="checkout, cart" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="checkout, cart" />
            </Helmet>
            <div className="container p-0 p-sm-2 my-5">
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="cart-sidebar">
                        {loadingCategories ? (
                            <div className="d-flex justify-content-center align-items-center p-5">
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : errorCategories ? (
                            <div className="d-flex justify-content-center">Error loading categories: {errorCategories.message}</div>
                        ) : (
                            <ShopSidebar categories={categories} brands={brands}/>
                        )}
                    </div>
                    <div className="cart-productdetail-side">
                        <h3>Shopping Cart</h3>
                        <CartProductCard products={cart} />
                        <a href="/" className="continue-shopping-btn">
                            <FontAwesomeIcon icon={faAngleLeft} className="continue-icon" /> Continue shopping
                        </a>
                    </div>
                    <div className="cart-checkout-side">
                        <div className="cart-checkout-boxes">
                            <div className="cart-checkout-box-1">
                                <p className="d-flex justify-content-between">{cart.length} items <span>${totalPrice.toFixed(2)}</span></p>
                                <p className="d-flex justify-content-between d-none">Shipping <span>$0</span></p>
                            </div>
                            <div className="cart-checkout-box-2">
                                <p className="d-flex justify-content-between">Subtotal <span>${(totalPrice + 0).toFixed(2)}</span></p>
                                <p className="d-flex justify-content-between">Total <span>${(totalPrice + 0).toFixed(2)}</span></p>
                                <p className="d-flex justify-content-between d-none">Taxes: <span>$0.00</span></p>
                            </div>
                            <Link to="/checkout" className="checkout-btn">Checkout</Link>
                        </div>
                        <div className="website-policy-box">
                            <div className="d-flex">
                                <div className="website-policy-icon website-policy-security"></div>
                                <div className="website-policy-point">
                                    <p>Security policy <br />(edit with the Customer Reassurance module)</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="website-policy-icon website-policy-delivery"></div>
                                <div className="website-policy-point">
                                    <p>Delivery policy <br />(edit with the Customer Reassurance module)</p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="website-policy-icon website-policy-return"></div>
                                <div className="website-policy-point">
                                    <p className="return-policy-text">Return policy <br />(edit with the Customer Reassurance module)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;