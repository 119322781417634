// SignIn.js
import React, { useContext, useState, useEffect  } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../Context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet-async";

const envConstants = require('../Constants');

const SignIn = () => {
    const { user, login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [submitBtnText, setSubmitBtnText] = useState('Sign In');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (user) {
            navigate('/'); // Redirect to profile or home if already logged in
        }
    }, [user, navigate]);


    const submitForm = async (event) => {
        setSubmitBtnText('Signing In...');
        event.preventDefault();
        try {
            await login(email, password);
            toast.success('Successfully logged in!');
            setSubmitBtnText('Signed In, Redirecting...');
            navigate('/'); // Redirect to a home or a protected route
        } catch (error) {
            console.error('Login failed', error);
            setSubmitBtnText('Sign In');
            toast.error('Login failed. Please check your credentials.');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="container p-0 p-sm-2 my-5">
            <Helmet>
                <title>Sign In - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="login to yazi account" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="new account yazi, sign up yazi, login to account, yazi login" />
            </Helmet>
            <div className="p-2">
                <div className="h4">Log In To Your Account</div>
                <hr />
                <form className="p-2 p-md-4 border" onSubmit={submitForm}>
                    <div className="col-md-8 row m-auto">
                        <div className="col-12 form-group mb-3">
                            <input
                                type="email"
                                placeholder='Email'
                                className="form-control"
                                name="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete='off'
                            />
                        </div>
                        <div className="col-12 form-group mb-3 position-relative">
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder='Password'
                                className="form-control"
                                name="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete='off'
                            />
                            <label className="position-absolute" style={{ top: '8px', right: '30px', cursor: 'pointer' }} onClick={toggleShowPassword}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </label>
                        </div>
                        <div className="col-12 form-group mb-3 text-center">
                            <label className="form-check-label ms-2">
                                <Link to="/forget-password" className="text-decoration-none text-danger text-dark-hover">Forgot Password?</Link>
                            </label>
                        </div>
                        <div className="col-12 form-group mb-3 text-center">
                            <button type="submit" className="shop-btn">{submitBtnText}</button>
                        </div>
                        <div className="col-12 form-group mb-3 text-center">
                            <label className="form-check-label ms-2">
                                Don't have an account? <Link to="/signup" className="text-decoration-none text-danger text-dark-hover">Sign Up</Link>
                            </label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
