import React, { useEffect, useState } from "react";

const HomeSlider = () => {
    const [current, setCurrent] = useState(0);
    const images = [
        {
            url: '/assets/images/PKTool.jpg',
            h3: "All the tools you need",
            span: "Specialised",
            h2: "Tools & Tools",
            link: "/shop?page=1&brand=specialised-tools-and-tools",
            backgroundsize: "contain"
        },
        {
            url: '/assets/images/Radiator.png',
            h3: "All the tools you need",
            span: "Radiator Caps",
            h2: "",
            link: "/shop?child_category=Radiator-Caps&page=1",
            backgroundsize: "cover"
        },
        {
            url: '/assets/images/Headlight.png',
            h3: "All the tools you need",
            span: "Head Light",
            h2: "",
            link: "/shop?child_category=Headlamp-Globes---Halogen--Xenon&page=1",
            backgroundsize: "cover"
        }
    ];
    const imageSlides = document.getElementsByClassName("slide-image");
    const bubbles = document.getElementsByClassName("bubble-outer");

    const nextImage = () => {
        let newIndex = current === images.length - 1 ? 0 : current + 1;
        imageSlides[current].className = "slide-image leftOut";
        imageSlides[newIndex].className = "slide-image leftIn";
        updateBubbles(newIndex);
        setCurrent(newIndex);
    };

    const prevImage = () => {
        let newIndex = current === 0 ? images.length - 1 : current - 1;
        imageSlides[current].className = "slide-image rightOut";
        imageSlides[newIndex].className = "slide-image rightIn";
        updateBubbles(newIndex);
        setCurrent(newIndex);
    };

    const jumpImage = (e) => {
        let jumpIndex = parseInt(e.target.id);
        if (jumpIndex === current) return;
        if (jumpIndex - current >= 0) {
            imageSlides[current].className = "slide-image leftOut";
            imageSlides[jumpIndex].className = "slide-image leftIn";
        } else {
            imageSlides[current].className = "slide-image rightOut";
            imageSlides[jumpIndex].className = "slide-image rightIn";
        }
        updateBubbles(jumpIndex);
        setCurrent(jumpIndex);
    };

    const updateBubbles = (highlight) => {
        bubbles[current].style.background = "#616161";
        bubbles[current].style.width = "12px";
        bubbles[current].style.height = "12px";
        bubbles[highlight].style.background = "#e05c0b";
        bubbles[highlight].style.width = "35px";
        bubbles[highlight].style.height = "12px";
    };

    useEffect(() => {
        bubbles[current].style.background = "#e05c0b";
        bubbles[current].style.width = "35px";
        bubbles[current].style.height = "12px";

        const interval = setInterval(nextImage, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [current]);

    return (
        <div className="gallery-container">
            <div className="gallery-track">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className="slide-image"
                        style={
                            index === 0
                                ? {
                                    backgroundImage: `url(${image.url})`,
                                    transform: "translateX(0%)",
                                    backgroundSize: image.backgroundsize,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center"
                                }
                                : {
                                    backgroundImage: `url(${image.url})`,
                                    transform: "translateX(100%)",
                                    backgroundSize: image.backgroundsize,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center"
                                }
                        }

                    >
                        <div className="slider-texts">
                            <h3>{image.h3}</h3>
                            <h2><span>{image.span}</span>{image.h2}</h2>
                            <p><span>{image.p}</span>{image.span1}</p>
                            <a href={image.link}>Shop Now</a>
                        </div>
                    </div>
                ))}
            </div>
            <div className="gallery-footer">
                {images.map((_, index) => (
                    <div key={index} className="bubble-outer" onClick={jumpImage} id={index}>
                        <div className="bubble-inner" id={index}></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeSlider;
