import Headbar from "./Sub-Components/Headbar";
import Navbar from "./Sub-Components/Navbar";

const Header = () =>{
return(
    <>
    <Headbar />

    <Navbar />
    </>
)
}

export default Header;