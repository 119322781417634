import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../Context/CartContext';
import env_constants from '../Constants';

const { API_BASE_PUBLIC_URL } = env_constants;

const CartProductCard = ({ products }) => {
    const { dispatch } = useCart();
    const [cartEmpty, setCartEmpty] = useState(false);

    useEffect(() => {
        if (products.length === 0) {
            setCartEmpty(true);
            setTimeout(() => {
                window.location.href = '/'; // Redirect to home page after 3 seconds
            }, 3000);
        } else {
            setCartEmpty(false);
        }
    }, [products]);

    const increaseQuantity = (product) => {
        const newQuantity = product.quantity + 1;
        dispatch({ type: 'UPDATE_QUANTITY', payload: { id: product.id, quantity: newQuantity } });
    };

    const decreaseQuantity = (product) => {
        if (product.quantity > 1) {
            const newQuantity = product.quantity - 1;
            dispatch({ type: 'UPDATE_QUANTITY', payload: { id: product.id, quantity: newQuantity } });
        }
    };

    const removeProduct = (id) => {
        dispatch({ type: 'REMOVE_FROM_CART', payload: id });
    };

    if (!products) {
        return <p>Loading...</p>;
    }

    if (cartEmpty) {
        return <p>There are no products in the cart.</p>;
    }

    return (
        <div className="cart-products-boxes">
            {products.map((product, index) => (
                <div key={index} className="cart-products-box d-flex justify-content-between">
                    <div className="cart-product-image">
                        <img src={`${API_BASE_PUBLIC_URL}${product.featured_image}`} width={80} height={80} alt={product.title} />
                    </div>
                    <div className="cart-product-detail-box justify-content-between flex-wrap">
                        <div className="cart-product-detail">
                            <h4><a href={`/product/${encodeURIComponent(product.permalink)}`}>{product.title}</a></h4>
                            {product.discount_data ? (
                                <div>
                                    <p className="discounted-price">${product.discount_data.discounted_price.toFixed(2)}</p>
                                    {product.product_meta && Array.isArray(product.product_meta) && product.product_meta.find(meta => meta.option === '_price') ? (
                                        <p className="cart-discount">
                                            -{(product.discount_data.discount_value)}
                                            {product.discount_data.discount_type === '1' ? '$ ' : '% '}
                                            <span>
                                                ${parseFloat(product.product_meta.find(meta => meta.option === '_price').value).toFixed(2)}
                                            </span>
                                        </p>
                                    ) : null}
                                </div>
                            ) : (
                                <p>${(product.product_meta && Array.isArray(product.product_meta) && product.product_meta.find(meta => meta.option === '_price')) ? parseFloat(product.product_meta.find(meta => meta.option === '_price').value).toFixed(2) : '0.00'}</p>
                            )}
                        </div>
                        <div className="cart-product-price-quantity justify-content-between align-items-start">
                            <div className="quantity-box">
                                <input type='number' value={product.quantity} readOnly />
                                <div className='in-de-crease-btns'>
                                    <button className='cart-increase-btn' onClick={() => increaseQuantity(product)}>
                                        <FontAwesomeIcon icon={faAngleUp} size="2xs" />
                                    </button>
                                    <button className='cart-decrease-btn' onClick={() => decreaseQuantity(product)}>
                                        <FontAwesomeIcon icon={faAngleDown} size="2xs" />
                                    </button>
                                </div>
                            </div>
                            <h5>
                                {product.discount_data ? (
                                    <div>
                                        <p className="discounted-price">${(product.discount_data.discounted_price * product.quantity).toFixed(2)}</p>
                                    </div>
                                ) : (
                                    <p>${(product.product_meta && Array.isArray(product.product_meta) && product.product_meta.find(meta => meta.option === '_price')) ? (parseFloat(product.product_meta.find(meta => meta.option === '_price').value) * product.quantity).toFixed(2) : '0.00'}</p>
                                )}
                            </h5>
                            <button onClick={() => removeProduct(product.id)}>
                                <FontAwesomeIcon icon={faTrash} style={{ color: "#878787" }} className='deleteproducticon' />
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CartProductCard;
