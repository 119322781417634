import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Link, useLocation } from 'react-router-dom';

const CategoryPriceFilter = ({ categories, brands, onCategoryClick }) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const currentCategory = query.get('category');
    const currentChildCategory = query.get('child_category');
    const currentBrand = query.get('brand');

    const [dropdowns, setDropdowns] = useState({});
    const [range, setRange] = useState([130, 500]);

    const toggleDropdown = (index) => {
        setDropdowns((prevDropdowns) => ({
            ...prevDropdowns,
            [index]: !prevDropdowns[index],
        }));
    };

    const handleSliderChange = (value) => {
        setRange(value);
    };

    return (
        <>
            <div className="shop-category-box">
                <h3>Shop By Category</h3>
                <ul>
                    {categories.map((category, index) => (
                        <li key={index} className='sd-main-dropdown'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <Link
                                    to={`/shop?category=${category.sku}&page=1`}
                                    className={`sd-link ${currentCategory === category.sku ? 'text-danger' : ''}`}
                                    onClick={onCategoryClick}
                                >
                                    {category.title}
                                </Link>
                                <button
                                    className="subcategory-inner-dropdown"
                                    onClick={() => toggleDropdown(index)}
                                >
                                    +
                                </button>
                            </div>
                            {dropdowns[index] && category.child_categories.length > 0 && (
                                <ul className='sub-category-dropdown'>
                                    {category.child_categories.map((subCategory, subIndex) => (
                                        <li key={subIndex}>
                                            <Link
                                                className={`sd-link ${currentChildCategory === subCategory.child_sku ? 'text-danger' : ''}`}
                                                to={`/shop?child_category=${subCategory.child_sku}&page=1`}
                                                onClick={onCategoryClick}
                                            >
                                                {subCategory.child_title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>

            <div className='price-filter d-none'>
                <h3>Price</h3>
                <div className="price-range-slider">
                    <p className="range-value">
                        <input
                            type="text"
                            id="amount"
                            value={`$${range[0]} - $${range[1]}`}
                            readOnly
                        />
                    </p>
                    <Slider
                        range
                        min={130}
                        max={500}
                        value={range}
                        onChange={handleSliderChange}
                        trackStyle={[{ backgroundColor: '#f0f0f0' }]}
                        handleStyle={[
                            { borderColor: '#ed1c24', backgroundColor: '#ed1c24' },
                            { borderColor: '#ed1c24', backgroundColor: '#ed1c24' }
                        ]}
                    />
                </div>
            </div>
            <div className='shop-category-box mt-2'>
                <h3>Shop By Brands</h3>
                {brands?.map((brand) => (
                    <div className='my-2' key={brand.id}>
                        <Link
                            className={`text-decoration-none ${currentBrand === brand.permalink ? 'text-danger' : 'text-dark'}`}
                            to={`/shop?brand=${brand.permalink}&page=1`}
                            onClick={onCategoryClick}
                        >
                            {brand.title}
                        </Link>
                    </div>
                ))}
            </div>
        </>
    );
}

export default CategoryPriceFilter;
