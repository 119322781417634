import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCaretDown, faXmark, faAngleUp, faAngleDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../Context/CartContext';
import { AuthContext } from '../../Context/AuthContext';
import env_constants from '../../Constants';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from '../../Axiosapi';
import _ from 'lodash';

const { API_BASE_URL, API_BASE_PUBLIC_URL } = env_constants;

const Headbar = () => {
    const [AccountDrop, setAccountDrop] = useState(false);
    const { cart, dispatch } = useCart();
    const [sidebar, setSidebar] = useState(false);
    const { user, logout, token } = useContext(AuthContext);
    const [profile, setProfile] = useState();
    const [loading, setLoading] = useState(true);
    const [search, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [searchError, setSearchError] = useState('');
    const [selectedResultIndex, setSelectedResultIndex] = useState(-1);
    const searchResultRefs = useRef([]);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const isProductPage = location.pathname.startsWith('/product/');
    const productimagenotfound = '/assets/images/product-image-not-found.png';

    const fetchProfile = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/profile`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setProfile(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching profile data:', error);
            setLoading(false);
        }
    };

    const debouncedSearch = useCallback(
        _.debounce(async (query) => {
            if (query.trim() !== '') {
                if (query.length < 3) {
                    setSearchError('Number of characters at least are 3');
                    setSearchResults([]);
                    return;
                } else {
                    setSearchError('');
                }
                setLoadingSearch(true);
                try {
                    const response = await axios.get(`${API_BASE_URL}/search-product`, {
                        params: { search: query },
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    if (response.data.data && response.data.data.length > 0) {
                        setSearchResults(response.data.data.slice(0, 15)); // Limit to 15 results
                    } else {
                        setSearchResults([]);
                    }
                } catch (error) {
                    console.error('Error fetching search results:', error);
                    setSearchResults([]);
                } finally {
                    setLoadingSearch(false);
                }
            } else {
                setSearchResults([]);
            }
        }, 300),  // 300ms debounce delay
        [token]
    );

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchQuery(value);
        setSelectedResultIndex(-1);
        debouncedSearch(value);
    };

    const handleKeyDown = (event) => {
        if (searchResults && searchResults.length > 0) {
            if (event.key === 'ArrowDown') {
                setSelectedResultIndex((prevIndex) => {
                    const nextIndex = (prevIndex + 1) % searchResults.length;
                    searchResultRefs.current[nextIndex]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    return nextIndex;
                });
            } else if (event.key === 'ArrowUp') {
                setSelectedResultIndex((prevIndex) => {
                    const nextIndex = (prevIndex - 1 + searchResults.length) % searchResults.length;
                    searchResultRefs.current[nextIndex]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    return nextIndex;
                });
            } else if (event.key === 'Enter') {
                if (selectedResultIndex >= 0) {
                    navigate(`/product/${encodeURIComponent(searchResults[selectedResultIndex].sku)}`);
                    handleSearchResultClick();
                } else {
                    navigate(`/search-results?query=${encodeURIComponent(search)}`);
                    handleSearchResultClick();
                }
            }
        }
    };

    useEffect(() => {
        if (token) {
            fetchProfile();
        } else {
            setLoading(false);
        }
    }, [token]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [searchResults, selectedResultIndex]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setAccountDrop(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const removeProduct = (id) => {
        dispatch({ type: 'REMOVE_FROM_CART', payload: id });
    };

    const toggleDropdownAccount = (event) => {
        event.preventDefault();
        setAccountDrop(!AccountDrop);
    };

    const cartSidebar = useCallback(
      _.throttle(() => {
        setSidebar((prevSidebar) => !prevSidebar);
      }, 1000),
      []
    );

    const isCartEmpty = !cart || cart.length === 0;

    const totalPrice = cart.reduce((total, product) => {
        const priceMeta = product.product_meta.find(meta => meta.option === '_price');
        const originalPrice = priceMeta ? parseFloat(priceMeta.value) : 0;

        const discountData = product.discount_data;
        let finalPrice = originalPrice;

        if (discountData && discountData.discounted_price) {
            finalPrice = parseFloat(discountData.discounted_price);
        }

        return total + product.quantity * finalPrice;
    }, 0);

    const handleSearchResultClick = () => {
        setSearchQuery('');
        setSearchResults([]);
        setSelectedResultIndex(-1);
    };

    const hideDropdown = () => {
        setAccountDrop(false);
    };

    const increaseQuantity = (product) => {
        const newQuantity = product.quantity + 1;
        dispatch({ type: 'UPDATE_QUANTITY', payload: { id: product.id, quantity: newQuantity } });
    };

    const decreaseQuantity = (product) => {
        if (product.quantity > 1) {
            const newQuantity = product.quantity - 1;
            dispatch({ type: 'UPDATE_QUANTITY', payload: { id: product.id, quantity: newQuantity } });
        }
    };

    return (
        <>
            <div className="topheadbar">
                <div className="container p-0 p-sm-2">
                    <div className="row row-col-2">
                        <div className="col top-head-text">
                            <p className="head-sale-text">Become a member to get 10% discount</p>
                        </div>
                        <div className="col text-end">
                            <ul className="top-head-feature">
                                <li className='position-relative account-icon'>
                                    {
                                        user ? (
                                            <button onClick={() => setAccountDrop(!AccountDrop)}>
                                                {user && user.name} <span className='ps-1'><FontAwesomeIcon icon={faCaretDown} /></span>
                                            </button>
                                        ) : (
                                            <Link to="/signin" className='text-danger text-decoration-none'><FontAwesomeIcon icon={faUser} className='me-2'/>Sign In</Link>
                                        )}
                                    {AccountDrop && user && (
                                        <div className='account-dropdown' ref={dropdownRef}>
                                            <ul>
                                                <li><Link to='/my-account' onClick={hideDropdown}>My Account</Link></li>
                                                <li><Link to='/order-history' onClick={hideDropdown}>Order History</Link></li>
                                                <li><Link to='/' className='sign-out-btn' onClick={() => { hideDropdown(); logout(); }}>Sign Out</Link></li>
                                            </ul>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="middleheadbar">
                <div className="container p-0 p-sm-2">
                    <div className="logo-search align-items-center">
                        <div className="header-logo">
                            <Link to='/'><img src="/yazi-logo.png" height={55} alt='' /></Link>
                        </div>
                        <div className="header-search">
                            <div className='position-relative searchbar'>
                                <input
                                    type="search"
                                    placeholder="Search Product Here..."
                                    value={search}
                                    onChange={handleSearchInputChange}
                                />
                                {search.trim() !== '' && (
                                    <div className="search-results">
                                        {searchError ? (
                                            <p>{searchError}</p>
                                        ) : loadingSearch ? (
                                            <p>Searching...</p>
                                        ) : (
                                            <>
                                                {searchResults.length === 0 && !isProductPage ? (
                                                    <p className="no-results">Data not found</p>
                                                ) : (
                                                    <>
                                                        {searchResults.map((result, index) => {
                                                            const priceMeta = result.product_meta.find(meta => meta.option === '_price');
                                                            const originalPrice = priceMeta ? parseFloat(priceMeta.value).toFixed(2) : '0.00';
                                                            const discountPrice = result.discount_data && result.discount_data.discounted_price ? parseFloat(result.discount_data.discounted_price).toFixed(2) : null;

                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={`search-result-item ${selectedResultIndex === index ? 'selected' : ''}`}
                                                                    ref={(el) => searchResultRefs.current[index] = el}
                                                                >
                                                                    <Link
                                                                        to={`/product/${encodeURIComponent(result.permalink)}`}
                                                                        className='search-link'
                                                                        onClick={handleSearchResultClick}
                                                                    >
                                                                        <div className="result-item-image">
                                                                            <img src={`${API_BASE_PUBLIC_URL}${result.featured_image}`} onError={(e) => e.target.src = productimagenotfound} alt={result.title} width={50} height={50} style={{ objectFit: 'contain' }} />
                                                                        </div>
                                                                        <div className="result-item-details">
                                                                            <h3 className="result-item-title">{result.title}</h3>
                                                                            <div className="result-item-price">
                                                                                {discountPrice ? (
                                                                                    <>
                                                                                        <span className="discounted-price me-3">${discountPrice}</span>
                                                                                        <span className="original-price"><del>${originalPrice}</del></span>
                                                                                    </>
                                                                                ) : (
                                                                                    <span>${originalPrice}</span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="header-cart">
                            <div className="d-flex align-items-center">
                                <div className="position-relative d-none">
                                    <a href="#" className="header-icon compare-icon"></a>
                                    <span className="icons-numbers">0</span>
                                </div>
                                <div className="position-relative d-none">
                                    <a href="#" className="header-icon heart-icon"></a>
                                    <span className="icons-numbers">0</span>
                                </div>
                                <div className="position-relative">
                                    <button className="header-icon cart-icon" onClick={cartSidebar}></button>
                                    <span className="icons-numbers">{cart.length}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`cartsidebar ${sidebar ? 'active' : ''}`}>
                <div className="cart-sd-header">
                    <h4 className="mb-0 cartsidebar-heading">Shopping Cart ({cart.length})</h4>
                    <button className="cartsidebar-close" onClick={cartSidebar}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className="cart-sd-body">
                    {isCartEmpty ? (
                        <p style={{ padding: "15px", textAlign: "center" }}>There are no products in the cart.</p>
                    ) : (
                        <div className='cart-sidebar-products'>
                            {cart.map((product, index) => (
                                <div key={index} className='cart-sd-product d-flex justify-content-between'>
                                    <div className='cart-sd-product-img'>
                                        <div>
                                            <img src={`${API_BASE_PUBLIC_URL}${product.featured_image}`} onError={(e) => e.target.src = productimagenotfound} width={80} height={80} alt={product.title} />
                                        </div>
                                    </div>
                                    <div className='cart-sd-product-detail d-flex justify-content-between'>
                                        <div className='sd-product-detail'>
                                            <h3>{product.title}</h3>
                                            <div className='d-flex align-items-center'>
                                                <span>X {product.quantity}</span>
                                                <div className="quantity-box ms-5">
                                                    <input type='number' value={product.quantity} readOnly />
                                                    <div className='in-de-crease-btns'>
                                                        <button className='cart-increase-btn' onClick={() => increaseQuantity(product)}>
                                                            <FontAwesomeIcon icon={faAngleUp} size="2xs" />
                                                        </button>
                                                        <button className='cart-decrease-btn' onClick={() => decreaseQuantity(product)}>
                                                            <FontAwesomeIcon icon={faAngleDown} size="2xs" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {product.discount_data ? (
                                                <p className="discounted-price">${product.discount_data.discounted_price.toFixed(2)}</p>
                                            ) : (
                                                <p>${(product.product_meta && Array.isArray(product.product_meta) && product.product_meta.find(meta => meta.option === '_price')) ? parseFloat(product.product_meta.find(meta => meta.option === '_price').value).toFixed(2) : '0.00'}</p>
                                            )}

                                        </div>
                                        <div className='cart-sd-product-delete'>
                                            <button onClick={() => removeProduct(product.id)}><FontAwesomeIcon icon={faTrash} style={{ color: "#878787" }} className='deleteproducticon' /></button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {!isCartEmpty && (
                        <div className='cart-sidebar-total'>
                            <div className='cart-total-one'>
                                <p>Item {cart.length} <span>${totalPrice.toFixed(2)}</span></p>
                                <p className='d-none'>Shipping <span>$0</span></p>
                            </div>
                            <div className='cart-total-two'>
                                <p>Subtotal <span>${(totalPrice + 0).toFixed(2)}</span></p>
                                <p>Total <span>${(totalPrice + 0).toFixed(2)}</span></p>
                                <p className='d-none'>Taxes: <span>$0.00</span></p>
                            </div>
                            <div className='cart-checkout-btns'>
                                <Link to="/cart" onClick={cartSidebar}>View Cart</Link>
                                <Link to="/checkout" onClick={cartSidebar}>Checkout</Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {sidebar && <div className="overlay"></div>}
        </>
    );
};

export default Headbar;
