import React from "react";
import CategoryPriceFilter from "./Sub-Components/CategoryPriceFilter";

const ShopSidebar = React.memo(({ categories, brands, onCategoryClick }) => {
    return (
        <div>
            <CategoryPriceFilter categories={categories} brands={brands} onCategoryClick={onCategoryClick} />
        </div>
    )
});

export default ShopSidebar;
