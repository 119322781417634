import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../Axiosapi';
import env_constants from '../Constants';
import { AuthContext } from '../Context/AuthContext';
import { Helmet } from "react-helmet-async";

const envConstants = env_constants;

const MyAccount = () => {
    const [profile, setProfile] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(true);
    const { token, updateUserProfile } = useContext(AuthContext);
    const [deletingAddressId, setDeletingAddressId] = useState(null); // Add this state
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${env_constants.API_BASE_URL}/profile`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const data = response.data.data;

                // Transform the user_meta array into key-value pairs
                const businessDetails = data.user_meta.reduce((acc, meta) => {
                    acc[meta.option] = meta.value;
                    return acc;
                }, {});

                // Combine the transformed business details with the rest of the profile data
                const profileData = {
                    ...data,
                    ...businessDetails
                };

                setProfile(profileData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile data:', error);
                setLoading(false);
            }
        };

        const fetchAddresses = async () => {
            try {
                const response = await axios.get(`${env_constants.API_BASE_URL}/user-addresses`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                // Assuming response.data.data is the list of addresses
                setAddresses(response.data.data.shipping_addresses);
            } catch (error) {
                console.error('Error fetching addresses:', error);
            }
        };

        if (token) {
            fetchProfile();
            fetchAddresses();
        } else {
            setLoading(false);
        }
    }, [token, updateUserProfile]);

    useEffect(() => {
        if (!loading && !token) {
            navigate('/signin');
        }
    }, [loading, token, navigate]);

    const deleteAddress = async (addressId) => {
        setDeletingAddressId(addressId); // Set the deleting address ID
        try {
            await axios.delete(`${env_constants.API_BASE_URL}/user-addresses/${addressId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setAddresses(addresses.filter(address => address.id !== addressId));
        } catch (error) {
            console.error('Error deleting address:', error);
        } finally {
            setDeletingAddressId(null); // Reset the deleting address ID
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!token) {
        return null; // or you can return a message or another component indicating the redirection
    }

    const hasBusinessDetails = profile && (profile._b_name || profile._b_email || profile._b_number || profile._b_street_address || profile._b_area_address);

    return (
        <div className="container py-4">
            <Helmet>
                <title>My Profile - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Login to Account" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="new account yazi, sign up yazi" />
            </Helmet>
            <div className="main">
                <h2>My Account</h2>
                <div className="card">
                    <div className="card-body">
                        <h2>Personal Detail</h2>
                        {profile ? (
                            <>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td>:</td>
                                            <td>{profile.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>:</td>
                                            <td>{profile.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>:</td>
                                            <td>{profile.phone}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {hasBusinessDetails && (
                                    <div>
                                        <h2 className='mt-3'>Business Detail</h2>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className='pb-2' style={{ width: '26.5%' }}>Name</td>
                                                    <td className='pb-2'>:</td>
                                                    <td className='pb-2'>{profile._b_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className='pb-2' style={{ width: '26.5%' }}>Email</td>
                                                    <td className='pb-2'>:</td>
                                                    <td className='pb-2'>{profile._b_email}</td>
                                                </tr>
                                                <tr>
                                                    <td className='pb-2' style={{ width: '26.5%' }}>Phone</td>
                                                    <td className='pb-2'>:</td>
                                                    <td className='pb-2'>{profile._b_number}</td>
                                                </tr>
                                                <tr>
                                                    <td className='pb-2' style={{ width: '26.5%' }}>Street Address</td>
                                                    <td className='pb-2'>:</td>
                                                    <td className='pb-2'>{profile._b_street_address}</td>
                                                </tr>
                                                <tr>
                                                    <td className='pb-2' style={{ width: '26.5%' }}>Area Address</td>
                                                    <td className='pb-2'>:</td>
                                                    <td className='pb-2'>{profile._b_area_address}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                {addresses.length > 0 && (
                                    <div className='row'>
                                        <h2 className='mt-3'>Addresses</h2>
                                        {addresses.map((address, index) => (
                                            <div key={index} className='col-md-4 border ms-3 rounded p-3 mb-3'>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className='pb-2'>First Name</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.f_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pb-2'>Last Name</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.l_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pb-2'>Email</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pb-2'>Phone</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pb-2'>City</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.city}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pb-2'>State</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.state}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pb-2'>Suburb</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.suburb}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pb-2'>Post Code</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.post_code}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pb-2'>Street Address</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.street_address}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='pb-2'>Address Type</td>
                                                            <td className='pb-2'>:</td>
                                                            <td className='pb-2'>{address.address_type}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                
                                                <button
                                                    onClick={() => deleteAddress(address.id)}
                                                    className="profile-edit-btn ms-3 border-0"
                                                    disabled={deletingAddressId === address.id} // Disable button if deleting
                                                >
                                                    {deletingAddressId === address.id ? 'Deleting...' : 'Delete'}
                                                </button>
                                                <Link to={`/update-address/${address.id}`} className="profile-edit-btn">Edit</Link>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        ) : (
                            <div>No profile data available.</div>
                        )}
                        <Link to="/Address" className="profile-edit-btn ms-3">Add Address</Link>
                        <Link to="/profile-update" className="profile-edit-btn">Edit Profile</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyAccount;
