import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../Context/CartContext';
import CheckoutForm from '../Components/CheckoutForm';
import { Helmet } from "react-helmet-async";
import envConstants from "../Constants";
import axios from "../Axiosapi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { AuthContext } from '../Context/AuthContext';

const { API_BASE_PUBLIC_URL } = envConstants;

const Checkout = () => {
    const { cart, dispatch } = useCart();
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const [brands, setBrands] = useState([]);
    const [error, setError] = useState(null);
    const [isAccordionOpen, setIsAccordionOpen] = useState(true);
    const [gst, setGst] = useState(0);
    const [deliveryCharges, setDeliveryCharges] = useState(0);
    const [userData, setUserData] = useState(null);
    const [signupDiscount, setSignupDiscount] = useState(0);
    const [membershipPlans, setMembershipPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);


    useEffect(() => {
        if (!cart || cart.length === 0) {
            navigate('/'); // Redirect to home if cart is empty
        }
    }, [cart, navigate]);

    useEffect(() => {
        if (membershipPlans.length > 0) {
            setSelectedPlan(membershipPlans[0]);
        }
    }, [membershipPlans]);


    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const fetchBrands = async () => {
        try {
            const response = await axios.get(`${envConstants.API_BASE_URL}/brands`);
            const generalSettings = response.data.general_settings;
            setBrands(generalSettings);
            setGst(parseFloat(generalSettings._gst_percentage) || 0);
            setDeliveryCharges(parseFloat(generalSettings._delivery_charges) || 0);
        } catch (error) {
            setError(error);
            console.error(error);
        }
    };

    const fetchUser = async () => {
        try {
            if (user) {
                const response = await axios.get(`${envConstants.API_BASE_URL}/user`);
                setUserData(response.data.data);

                // Set signup discount from user data
                const signupPlan = response.data.data.signup_plans[0];
                if (signupPlan) {
                    setSignupDiscount(parseFloat(signupPlan.discount_percentage) || 0);
                }

                // Set membership plans
                setMembershipPlans(response.data.data.user_membership_plans);
            }
        } catch (error) {
            setError(error);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchBrands();
        fetchUser();
    }, [user]);

    const subtotal = cart.reduce((total, product) => {
        const priceMeta = product.product_meta.find(meta => meta.option === '_price');
        const originalPrice = priceMeta ? parseFloat(priceMeta.value) : 0;

        const discountData = product.discount_data;
        const finalPrice = discountData && discountData.discounted_price
            ? parseFloat(discountData.discounted_price)
            : originalPrice;

        return total + product.quantity * finalPrice;
    }, 0);

    const totalDiscountApplied = cart.reduce((totalDiscount, product) => {
        const priceMeta = product.product_meta.find(meta => meta.option === '_price');
        const originalPrice = priceMeta ? parseFloat(priceMeta.value) : 0;

        const discountData = product.discount_data;
        const finalPrice = discountData && discountData.discounted_price
            ? parseFloat(discountData.discounted_price)
            : originalPrice;

        const finalGrandPrice = originalPrice - finalPrice;

        return totalDiscount + (finalGrandPrice * product.quantity);
    }, 0);

    const finalDiscountAmount = totalDiscountApplied;

    // Apply signup discount
    const signupDiscountAmount = (subtotal * signupDiscount) / 100;

    // Apply selected plan discount if available
    const planDiscountAmount = selectedPlan ? (subtotal * parseFloat(selectedPlan.discount_percentage)) / 100 : 0;

    const shippingCost = subtotal >= 100 ? 0 : deliveryCharges;
    const subtotalAfterDiscount = subtotal - signupDiscountAmount - planDiscountAmount;
    const shippsub = subtotalAfterDiscount + shippingCost;
    const gstAmount = shippsub * gst / 100;
    const totalIncludingTax = subtotalAfterDiscount - finalDiscountAmount + shippingCost + gstAmount;

    const increaseQuantity = (product) => {
        const newQuantity = product.quantity + 1;
        dispatch({ type: 'UPDATE_QUANTITY', payload: { id: product.id, quantity: newQuantity } });
    };

    const decreaseQuantity = (product) => {
        if (product.quantity > 1) {
            const newQuantity = product.quantity - 1;
            dispatch({ type: 'UPDATE_QUANTITY', payload: { id: product.id, quantity: newQuantity } });
        }
    };

    const removeProduct = (id) => {
        dispatch({ type: 'REMOVE_FROM_CART', payload: id });
    };

    const handlePlanChange = (event) => {
        const selectedPlanId = event.target.value;
        const plan = membershipPlans.find(plan => plan.id === parseInt(selectedPlanId, 10));
        setSelectedPlan(plan);
    };

    return (
        <div className="checkout-section">
            <Helmet>
                <title>Checkout - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="checkout, cart" />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="checkout, cart" />
            </Helmet>
            <div className="container p-0 p-sm-2 my-5">
                <div className="checkout-boxes justify-content-between">
                    <div className="checkout-detail-box">
                        <CheckoutForm
                            subtotal={subtotal}
                            signupDiscount={signupDiscountAmount}
                            shippingCost={shippingCost}
                            gstAmount={gstAmount}
                            planDiscount={planDiscountAmount}
                            shippsub={shippsub}
                            totalIncludingTax={totalIncludingTax}
                            planDiscountAmount={planDiscountAmount}
                        />
                    </div>
                    <div className="checkout-sidebar">
                        <div>
                            <div className="checkout-product-detail-box">
                                <p className="product-count">{cart.length} items</p>
                                <section className="checkout-cart-accordion">
                                    <div className="checkout-cart-tab">
                                        <input
                                            type="checkbox"
                                            name="checkout-cart-1"
                                            id="cc1-card"
                                            checked={isAccordionOpen}
                                            onChange={toggleAccordion}
                                        />
                                        <label htmlFor="cc1-card" className="checkout-tab-label">Show Details</label>
                                        <div className={`checkout-cart-tab__content ${isAccordionOpen ? 'open' : ''}`}>
                                            {cart.map((product, index) => (
                                                <div key={index} className="checkout-product-boxes d-flex justify-content-between">
                                                    <div className="checkout-product-img">
                                                        <img src={`${API_BASE_PUBLIC_URL}${product.featured_image}`} width={70} height={70} alt={product.title} />
                                                    </div>
                                                    <div className="checkout-product-detail position-relative">
                                                        <button onClick={() => removeProduct(product.id)} className='border-0 bg-transparent position-absolute' style={{ top: '0', right: '0' }}>
                                                            <FontAwesomeIcon icon={faTrash} style={{ color: "#878787" }} className='deleteproducticon' />
                                                        </button>
                                                        <h3>{product.title}</h3>
                                                        {product.discount_data ? (
                                                            <div>
                                                                <h6 className="m-0 fw-medium">
                                                                    SKU: {product.sku || 'N/A'}
                                                                </h6>
                                                                <div className="quantity-box my-1">
                                                                    <input type='number' value={product.quantity} readOnly />
                                                                    <div className='in-de-crease-btns'>
                                                                        <button className='cart-increase-btn' onClick={() => increaseQuantity(product)}>
                                                                            <FontAwesomeIcon icon={faAngleUp} size="2xs" />
                                                                        </button>
                                                                        <button className='cart-decrease-btn' onClick={() => decreaseQuantity(product)}>
                                                                            <FontAwesomeIcon icon={faAngleDown} size="2xs" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <p className="discounted-price">
                                                                    ${product.discount_data.discounted_price.toFixed(2)}
                                                                </p>
                                                                {product.product_meta && Array.isArray(product.product_meta) && product.product_meta.find(meta => meta.option === '_price') ? (
                                                                    <p className="cart-discount">
                                                                        -{(product.discount_data.discount_value)}
                                                                        {product.discount_data.discount_type === '1' ? '$ ' : '% '}
                                                                        <span>
                                                                            ${parseFloat(product.product_meta.find(meta => meta.option === '_price').value).toFixed(2)}
                                                                        </span>
                                                                    </p>
                                                                ) : null}
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <h6 className="m-0 fw-medium">
                                                                    SKU: {product.sku || 'N/A'}
                                                                </h6>
                                                                <div className="quantity-box my-1">
                                                                    <input type='number' value={product.quantity} readOnly />
                                                                    <div className='in-de-crease-btns'>
                                                                        <button className='cart-increase-btn' onClick={() => increaseQuantity(product)}>
                                                                            <FontAwesomeIcon icon={faAngleUp} size="2xs" />
                                                                        </button>
                                                                        <button className='cart-decrease-btn' onClick={() => decreaseQuantity(product)}>
                                                                            <FontAwesomeIcon icon={faAngleDown} size="2xs" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <p>
                                                                    ${(product.product_meta && Array.isArray(product.product_meta) && product.product_meta.find(meta => meta.option === '_price')) ? parseFloat(product.product_meta.find(meta => meta.option === '_price').value).toFixed(2) : '0.00'}
                                                                </p>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </section>
                                <div className="cart-checkout-box-1">
                                    <p className="d-flex justify-content-between">Subtotal <span>${subtotal.toFixed(2)}</span></p>

                                    {user && (
                                        <>
                                            <p className="d-flex justify-content-between">Signup Discount <span>${signupDiscountAmount.toFixed(2)}</span></p>
                                            {membershipPlans.length > 0 && (
                                                <p className="d-flex justify-content-between">
                                                    Membership Plan Discount <span>${planDiscountAmount.toFixed(2)}</span>
                                                </p>
                                            )}
                                            {membershipPlans.length > 0 && (
                                                <select className='plan-select' value={selectedPlan ? selectedPlan.id : ''} onChange={handlePlanChange}>
                                                    {membershipPlans.map(plan => (
                                                        <option key={plan.id} value={plan.id}>
                                                            {plan.plan_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </>
                                    )}
                                    <p className="d-flex justify-content-between">Delivery Charges <span>${shippingCost.toFixed(2)}</span></p>
                                    <p className="d-flex justify-content-between">GST <span>${gstAmount.toFixed(2)}</span></p>
                                </div>
                                <div className="cart-checkout-box-2">
                                    <p className="d-flex justify-content-between">Total <span>${totalIncludingTax.toFixed(2)}</span></p>
                                </div>
                            </div>

                            <div className="website-policy-box">
                                <div className="d-flex">
                                    <div className="website-policy-icon website-policy-security"></div>
                                    <div className="website-policy-point">
                                        <Link to="/privacy-and-policy" className='text-decoration-none text-dark'><p>Privacy Policy</p></Link>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="website-policy-icon website-policy-delivery"></div>
                                    <div className="website-policy-point">
                                        <Link to="/shipping-option" className='text-decoration-none text-dark'><p>Shipping Option</p></Link>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="website-policy-icon website-policy-return"></div>
                                    <div className="website-policy-point">
                                        <Link to="/return-policy" className='text-decoration-none text-dark'><p className="return-policy-text">Return Policy</p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkout;
