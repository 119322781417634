import { Helmet } from "react-helmet-async";
import envConstants from "../Constants";
const EditorialGuidelines = () => {
    return (
        <div className="container my-4">
            <Helmet>
                <title>Editorial Guidelines - {envConstants.STORE_NAME ? envConstants.STORE_NAME : 'Online Store'}</title>
                <meta name="description" content="Our core philosophy is simple: to be the leading industrial tool company in Australia. We achieve this by deeply valuing our customers, suppliers, and staff. We offer an extensive range of industrial tools at competitive prices, reinvesting our profits into the business to maintain low overheads and attract top talent." />
                <meta name="author" content={envConstants.STORE_META_AUTHOR ? envConstants.STORE_META_AUTHOR : 'Yazi Store'} />
                <meta name="keywords" content="yazi editorial guidelines, editorial guidelines yazi" />
            </Helmet>
            <h2>Editorial Guidelines</h2>
            <p>Established in Australia, Yazi Auto is a reputable online platform dedicated to providing consumers with a wide range of automotive products and accessories. At Yazi Auto, we understand the importance of independence, accuracy, and reliability when it comes to delivering valuable information to our users.</p>
            <h4>Our Mission</h4>
            <p>Our mission is to inform, educate, and assist consumers in making informed decisions about their automotive purchases. We pride ourselves on being an objective and trustworthy source of information, ensuring that our users can rely on us for accurate and up-to-date content.</p>
            <h4>Core Values</h4>
            <h5>Independence</h5>
            <p>Independence is a core value at Yazi Auto. We are not affiliated with any specific brands or companies, allowing us to provide unbiased reviews, comparisons, and recommendations. Our team of experts conducts extensive research and analysis to deliver objective and fair assessments of various automotive products and accessories.</p>
            <h5>Accuracy</h5>
            <p>Accuracy is paramount in everything we do. We strive to provide our users with accurate and reliable information that they can depend on. Our content goes through a rigorous review process to ensure its completeness, currency, and factual accuracy. If any mistakes are identified, we take immediate action to rectify them.</p>
            <h5>Originality</h5>
            <p>Originality is another aspect that sets us apart. We take pride in creating unique and engaging content for our users. Our talented team of writers and automotive enthusiasts work tirelessly to produce original articles, guides, and reviews that provide valuable insights into the latest automotive trends and products.</p>
            <h5>Usability</h5>
            <p>Usability is a key focus at Yazi Auto. We understand that automotive technology can sometimes be complex, so we strive to present information in a clear, accessible, and practical manner. Our aim is to empower users with the knowledge they need to make better-informed decisions about their automotive purchases.</p>
            <h5>Accountability</h5>
            <p>
                Accountability is a fundamental principle at Yazi Auto. We take responsibility for the content we publish and are committed to addressing any concerns or issues raised by our users. If you have any questions or feedback about our content, please don’t hesitate to reach out to us.
                Thank you for choosing Yazi Auto as your trusted source for all things automotive-related. We are dedicated to helping you find the best automotive products and accessories that suit your needs. Explore our website today and discover the latest automotive trends, reviews, and buying guides.
            </p>
        </div>
    )
}

export default EditorialGuidelines;