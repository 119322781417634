import React, { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useCart } from '../Context/CartContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from 'react-router-dom';

function ProductDetail({ product }) {
  const [value, setValue] = useState(1);
  const min = 1;
  const max = 100;
  const { dispatch } = useCart();

  const handleIncrement = () => {
    setValue((prevValue) => (prevValue < max ? prevValue + 1 : prevValue));
  };

  const handleDecrement = () => {
    setValue((prevValue) => (prevValue > min ? prevValue - 1 : prevValue));
  };

  const handleAddToCart = () => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingItemIndex = cartItems.findIndex(item => item.id === product.id);

    if (existingItemIndex >= 0) {
      cartItems[existingItemIndex].quantity += value;
    } else {
      cartItems.push({ ...product, quantity: value });
    }

    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    dispatch({ type: 'ADD_TO_CART', payload: { ...product, quantity: value } });
    toast.success(`${product.title} added to cart!`, {
      position: "top-right",
      autoClose: 3000, // 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
          color: "black", 
          fontWeight: "bold"
      },
      toastStyle: {
          backgroundColor: "red", 
      },
      iconTheme: {
          primary: "red", 
      }
  });
  };

  const removeTags = (str) => {
    if (!str || typeof str !== 'string') return '';
    return str.replace(/<[^>]*>?/gm, '');
  };

  const originalPrice = parseFloat(product?.product_meta?.find(meta => meta.option === '_price')?.value || 0);
  const discountValue = product?.discount_data?.discount_value || 0;
  const discountType = product?.discount_data?.discount_type;

  let discountedPrice = originalPrice;
  let discountDisplay = '';

  if (discountValue) {
    if (discountType === "0") {
      discountDisplay = `Save ${discountValue}%`;
      discountedPrice -= (originalPrice * (discountValue / 100));
    } else if (discountType === "1") {
      discountDisplay = `Save $${discountValue}`;
      discountedPrice -= discountValue;
    }
  }

  return (
    <>
      <div className="product_detail">
        <div>
          <h2 className="m-0 title">
            {product?.title || 'No Title'}
          </h2>
          <div className="star-rating">
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star">&#9733;</span>
            <span className="star gray">&#9733;</span>
            <span className="star gray">&#9733;</span>
          </div>

          <ol className="breadcrumb1">
          <li className="font-weight-medium small m-1 border-end pe-2">
              <Link to={`/shop?page=1&brand=${encodeURIComponent(product?.brand?.permalink || '')}`} className="text-decoration-none">
                <span className="text_color fw-medium">Brand: {product?.brand?.title || 'Unknown'}</span>
              </Link>
            </li>

            <li className="font-weight-medium small m-1 pe-2">
                <span className="text_color fw-medium">
                  SKU: {product?.sku || 'N/A'}
                </span>
            </li>
          </ol>

          <hr className="mt-1 mb-3" />

          <p className="p_description">
            {product?.short_description ? removeTags(product.short_description) : "No Description"}
          </p>

          <div className="item-price d-flex">
            <p className="text_size1">${discountedPrice.toFixed(2)}</p>
            {discountValue > 0 && (
              <>
                <s className="ms-2 text_size2">${originalPrice.toFixed(2)}</s>
                <p className="ms-2 text_size3">{discountDisplay}</p>
              </>
            )}
          </div>
          <p className="text_size4">
            Free Shipping (Est. Delivery Time 2-3 Days)
          </p>
          <hr className="hr_line" />
          <div className="d-inline-flex">
            <div className="quantity">
              <input type="number" value={value} min={min} max={max} readOnly />
              <div className="quantity-nav">
                <div
                  className="quantity-button quantity-up"
                  onClick={handleIncrement}
                >
                  <KeyboardArrowUpIcon className="quantity-down-font" />
                </div>
                <div
                  className="quantity-button quantity-down"
                  onClick={handleDecrement}
                >
                  <KeyboardArrowDownIcon className="quantity-down-font" />
                </div>
              </div>
            </div>

            <div className="add ms-3">
              <button className="addtocart_btn" type="button" onClick={handleAddToCart}>
                ADD TO CART
              </button>
            </div>
          </div>

          <hr className="hr_line" />
        </div>
      </div>
    </>
  );
}

export default ProductDetail;