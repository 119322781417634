import React from "react";
import env_constants from "../Constants";
import { Link } from "react-router-dom";

const CategoryCard = ({categories}) => {
    return (
        <div className="d-flex justify-content-center mt-4 flex-wrap category-boxes">
            {categories.slice(0, 12).map((category) => (
                <div className="category-card text-center">
                    <Link to={`/shop?category=`+category.sku} className="category-link">
                        <img src={category.thumbnail_image ? env_constants.API_BASE_PUBLIC_URL+category.thumbnail_image : 'https://via.placeholder.com/150'} className="category-img" />
                        <h4 className="category-title">{category.title}</h4>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default CategoryCard;